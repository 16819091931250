import React, { useState, useEffect, useRef } from "react";
import Layout from "../components/layout/Layout";
import axios from "axios";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { openDB } from "idb";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import '.././style/Card.css';
import HowToVoteOutlinedIcon from '@mui/icons-material/HowToVoteOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import HolidayVillageOutlinedIcon from '@mui/icons-material/HolidayVillageOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import FamilyRestroomOutlinedIcon from '@mui/icons-material/FamilyRestroomOutlined';
import { IconButton } from "@mui/material";
import { useAuth } from "../context/auth";


const HomePage = () => {
  const [data, setData] = useState([]);
  const [familyData, setFamilyData] = useState([]);  // State for family data
  const [boothData, setBoothData] = useState([]);    // State for booth data
  const [error, setError] = useState(null);
  const [votedCount, setVotedCount] = useState(0);
  const [nonVotedCount, setNonVotedCount] = useState(0);
  const [totalVoter, setTotalVoter] = useState(0);
  const notyf = new Notyf();
  const [totalVillages, setTotalVillages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalFamily, setTotalFamily] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [moreDataAvailable, setMoreDataAvailable] = useState(true);
  const pageSize = 100;
  const containerRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();

    if (auth?.user?.role === 0 || auth?.user?.role === 3) {
      fetchFamilyData();
    } else {
      fetchVillageData();
    }

    window.addEventListener("online", handleOnline);
    return () => {
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  const fetchData = async () => {
    try {
      const localData = await getLocalData();
      if (navigator.onLine) {
        const serverData = await fetchDataFromServer();
        console.log(`Fetched ${serverData.length} items from server`);
        setData(serverData);
        await saveToLocalData(serverData);
      } else {
        if (localData && localData.length > 0) {
          console.log(`Using ${localData.length} items from local storage`);
          setData(localData);
        } else {
          setError("No data available");
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("An error occurred while fetching data.");
    }
  };

  const handleOnline = () => {
    fetchData();
  };

  const dbName = "voters-db";

  const getLocalData = async () => {
    try {
      const db = await openDB(dbName, 1, {
        upgrade(db) {
          db.createObjectStore("votes", { keyPath: "FM_NAME_EN" });
        },
      });
      const tx = db.transaction("votes", "readonly");
      const store = tx.objectStore("votes");
      return store.getAll();
    } catch (error) {
      console.error("Error retrieving local data:", error);
      return [];
    }
  };

  const saveToLocalData = async (newData) => {
    try {
      if (!newData || !Array.isArray(newData)) {
        console.warn("No new data provided or data is not an array");
        return;
      }

      console.log(`Total new data items to be saved: ${newData.length}`);

      const db = await openDB(dbName, 1, {
        upgrade(db) {
          db.createObjectStore("votes", { keyPath: "FM_NAME_EN" });
        },
      });

      const tx = db.transaction("votes", "readonly");
      const store = tx.objectStore("votes");
      const existingData = await store.getAll();

      console.log(`Existing data items in IndexedDB: ${existingData.length}`);

      if (existingData.length > 0) {
        console.log("Data already exists in IndexedDB, skipping update.");
        return;
      }

      const txWrite = db.transaction("votes", "readwrite");
      const storeWrite = txWrite.objectStore("votes");

      let processedCount = 0;

      for (const item of newData) {
        storeWrite.put(item);
        processedCount++;
      }

      await txWrite.done;

      console.log(`Total new data items processed and saved: ${processedCount}`);
      notyf.success("Data saved locally");

      const finalCount = await store.getAll();
      console.log(`Final data items in IndexedDB after save: ${finalCount.length}`);
    } catch (error) {
      console.error("Error saving data to IndexedDB:", error);
    }
  };

  const authData = localStorage.getItem("auth");
  const authObject = JSON.parse(authData);
  const user = authObject.user;
  const [start, end] = user.partNoRanges;

  const fetchDataFromServer = async () => {
    try {
      const response = await axios.get(`/api/v1/product/allvoter`, {
        params: {
          electionType: user.electionType,
          state: user.state,
          district: user.district,
          assemblyName: user.assembly,
          constituency: user.constituency,
          start: start,
          end: end
        }
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching data from server:", error);
      throw error;
    }
  };

  useEffect(() => {
    calculateCounts();
  }, [data]);

  const calculateCounts = () => {
    let voted = 0;
    let nonVoted = 0;

    data.forEach(item => {
      if (item.voted) {
        voted++;
      } else {
        nonVoted++;
      }
    });

    setVotedCount(voted);
    setNonVotedCount(nonVoted);
    setTotalVoter(data.length);
  };

  const fetchVillageData = async () => {
    try {
      if (navigator.onLine) {
        const response = await axios.get(`/api/v1/product/village`, {
          params: {
            electionType: user.electionType,
            state: user.state,
            district: user.district,
            assemblyName: user.assembly,
            constituency: user.constituency,
            start: start,
            end: end
          }
        });
        console.log("API response:", response.data.data); // Log response for debugging
        setBoothData(response.data.data);
        setTotalVillages(response.data.partnoCount);
        setLoading(false);
      } else {
        const offlineData = await fetchVillageDataFromIndexedDB();
        setBoothData(offlineData);
        setTotalVillages(offlineData.length);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("An error occurred while fetching data.");
      setLoading(false);
    }
  };

  const fetchVillageDataFromIndexedDB = async () => {
    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open("voters-db", 1);

      request.onerror = (event) => {
        console.error("IndexedDB error:", event.target.error);
        reject("An error occurred while accessing IndexedDB.");
      };

      request.onsuccess = (event) => {
        const db = event.target.result;
        const transaction = db.transaction("votes", "readonly");
        const objectStore = transaction.objectStore("votes");

        const data = {};

        objectStore.openCursor().onsuccess = (event) => {
          const cursor = event.target.result;
          if (cursor) {
            const PART_NO = cursor.value.PART_NO;
            if (!data[PART_NO]) {
              data[PART_NO] = 1;
            } else {
              data[PART_NO]++;
            }
            cursor.continue();
          } else {
            resolve(
              Object.entries(data).map(([PART_NO, count]) => ({
                PART_NO,
                count,
              }))
            );
          }
        };

        objectStore.openCursor().onerror = (event) => {
          console.error("IndexedDB cursor error:", event.target.error);
          reject("An error occurred while fetching data from IndexedDB.");
        };
      };

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains("votes")) {
          db.createObjectStore("votes", { keyPath: "FM_NAME_EN" });
        }
      };
    });
  };

  const fetchFamilyData = async () => {
    try {
      if (navigator.onLine) {
        const response = await axios.get(`/api/v1/product/family`, {
          params: {
            electionType: user.electionType,
            state: user.state,
            district: user.district,
            assemblyName: user.assembly,
            constituency: user.constituency,
            start: start,
            end: end
          }
        });
        console.log("API response:", response.data.data); // Log response for debugging
        setFamilyData(response.data.data);
        setTotalFamily(response.data.familyCount);
        setLoading(false);
      } else {
        const offlineData = await fetchFamilyDataFromIndexedDB();
        setFamilyData(offlineData);
        setTotalFamily(offlineData.length);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("An error occurred while fetching data.");
      setLoading(false);
    }
  };

  const fetchFamilyDataFromIndexedDB = async () => {
    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open("voters-db", 1);

      request.onerror = (event) => {
        console.error("IndexedDB error:", event.target.error);
        reject("An error occurred while accessing IndexedDB.");
      };

      request.onsuccess = (event) => {
        const db = event.target.result;
        const transaction = db.transaction("votes", "readonly");
        const objectStore = transaction.objectStore("votes");

        const familyMap = new Map();

        objectStore.openCursor().onsuccess = (event) => {
          const cursor = event.target.result;
          if (cursor) {
            const familyHead = cursor.value.FM_NAME_EN;
            if (!familyMap.has(familyHead)) {
              familyMap.set(familyHead, {
                familyHead,
                members: 1
              });
            } else {
              familyMap.get(familyHead).members++;
            }
            cursor.continue();
          } else {
            resolve(Array.from(familyMap.values()));
          }
        };

        objectStore.openCursor().onerror = (event) => {
          console.error("IndexedDB cursor error:", event.target.error);
          reject("An error occurred while fetching data from IndexedDB.");
        };
      };

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains("votes")) {
          db.createObjectStore("votes", { keyPath: "FM_NAME_EN" });
        }
      };
    });
  };

  const handleScroll = async () => {
    const container = containerRef.current;
    if (
      container.scrollTop + container.clientHeight >=
      container.scrollHeight - 10
    ) {
      if (moreDataAvailable && !fetching) {
        setFetching(true);
        const newPage = currentPage + 1;
        const newData = await fetchMoreData(newPage);
        if (newData.length < pageSize) {
          setMoreDataAvailable(false);
        }
        setCurrentPage(newPage);
        setFetching(false);
      }
    }
  };

  const fetchMoreData = async (page) => {
    try {
      const response = await axios.get(`/api/v1/product/allvoter`, {
        params: {
          electionType: user.electionType,
          state: user.state,
          district: user.district,
          assemblyName: user.assembly,
          constituency: user.constituency,
          start: start,
          end: end,
          page: page,
          pageSize: pageSize
        }
      });
      const newData = response.data;
      setData((prevData) => [...prevData, ...newData]);
      return newData;
    } catch (error) {
      console.error("Error fetching more data:", error);
      setError("An error occurred while fetching more data.");
      return [];
    }
  };




    return (
      <Layout title={"Voters App"}>
    <div className="container mt-3 bgHome">
        <div className="row">
          <div className="col-md-12 col-12">
            <div className="row">
              <div className="col-sm-12 mob_576">
                <div className="total_voter_container d-flex flex-wrap justify-content-center">
                  <div className="total_voters d-flex flex-wrap justify-content-center">
                    <div className="card-img w-100 text-center">
                      <IconButton>
                        <HowToVoteOutlinedIcon style={{ fontSize: '40px' }} />
                      </IconButton>
                    </div>
                    <div className="w-100 text-center">
                      <h6 className="card-title mt-2">Voters</h6>
                      <p> {totalVoter} </p>
                    </div>
                  </div>
                  <div className="pending_voters d-flex flex-wrap justify-content-center">
                    <div className="card-img w-100 text-center">
                      <IconButton>
                        <PendingActionsOutlinedIcon style={{ fontSize: '40px' }} />
                      </IconButton>
                    </div>
                    <div className="w-100 text-center">
                      <h6 className="card-title mt-2">Pending</h6>
                      <p> {nonVotedCount} </p>
                    </div>
                  </div>
                  <div className="voted_voters d-flex flex-wrap justify-content-center">
                    <div className="card-img w-100 text-center">
                      <IconButton>
                        <HowToVoteOutlinedIcon style={{ fontSize: '40px' }} />
                      </IconButton>
                    </div>
                    <div className="w-100 text-center">
                      <h6 className="card-title mt-2">Voted</h6>
                      <p> {votedCount} </p>
                    </div>
                  </div>
                  <div className="add_voters d-flex flex-wrap justify-content-center">
                  <Link to="/addvoter" className="card-link">
                    <div className="card-img w-100 text-center">
                      <IconButton>
                        <AddCircleOutlineIcon style={{ fontSize: '40px' }} />
                      </IconButton>
                    </div>
                    <div className="w-100 text-center">
                      <h6 className="card-title mt-2">Add New</h6>
              <p>Voter </p>
                    </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-sm p-2 desk_top">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex flex-wrap justify-content-center">
                      <div className="card-img w-100 text-center">
                        <IconButton>
                          <HowToVoteOutlinedIcon style={{ fontSize: '40px' }} />
                        </IconButton>
                      </div>
                      <div className="w-100 text-center">
                        <h6 className="card-title mt-2">Total Voters</h6>
                        <p> {totalVoter} </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm p-2 desk_top">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex flex-wrap justify-content-center">
                      <div className="card-img w-100 text-center">
                        <IconButton>
                          <PendingActionsOutlinedIcon style={{ fontSize: '40px' }} />
                        </IconButton>
                      </div>
                      <div className="w-100 text-center">
                        <h6 className="card-title mt-2">Pending</h6>
                        <p> {nonVotedCount} </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm p-2 desk_top">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex flex-wrap justify-content-center">
                      <div className="card-img w-100 text-center">
                        <IconButton>
                          <HowToVoteOutlinedIcon style={{ fontSize: '40px' }} />
                        </IconButton>
                      </div>
                      <div className="w-100 text-center">
                        <h6 className="card-title mt-2">Voted</h6>
                        <p> {votedCount} </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm p-2 desk_top">
              <Link to="/addvoter" className="card-link">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex flex-wrap justify-content-center">
                      <div className="card-img w-100 text-center">
                        <IconButton>
                          <AddCircleOutlineIcon style={{ fontSize: '40px' }} />
                        </IconButton>
                      </div>
                      <div className="w-100 text-center">
                        <h6 className="card-title mt-2">Add New</h6>
                        <p> Voter </p>
                      </div>
                    </div>
                  </div>
                </div>
                </Link>
              </div>
            </div>

          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-6 p-2">
            <Link to="/voterList" className="card-link">
              <div className="card">
                <div className="card-body d-flex justify-content-center flex-wrap">
                  <div className="card-img w-100">
                    <IconButton>
                      <SearchOutlinedIcon style={{ fontSize: '40px' }} />
                    </IconButton>
                  </div>
                  <h4 className="card-title mt-2 w-100">Search</h4>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-3 col-6 p-2">
            <Link to="/villages" className="card-link">
              <div className="card">
                <div className="card-body d-flex justify-content-center flex-wrap">
                  <div className="card-img w-100">
                    <IconButton>
                      <HolidayVillageOutlinedIcon style={{ fontSize: '40px' }} />
                    </IconButton>
                  </div>
                  <h4 className="card-title mt-2 w-100">Parts</h4>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-3 col-6 p-2">
            <Link to="/check" className="card-link">
              <div className="card">
                <div className="card-body d-flex justify-content-center flex-wrap">
                  <div className="card-img w-100">
                    <IconButton>
                      <ListAltOutlinedIcon style={{ fontSize: '40px' }} />
                    </IconButton>
                  </div>
                  <h4 className="card-title mt-2 w-100">All List</h4>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-md-3 col-6 p-2">
            <Link to="/family" className="card-link">
              <div className="card">
                <div className="card-body d-flex justify-content-center flex-wrap">
                  <div className="card-img w-100">
                    <IconButton>
                      <FamilyRestroomOutlinedIcon style={{ fontSize: '40px' }} />
                    </IconButton>
                  </div>
                  <h4 className="card-title mt-2 w-100 text-center">Family</h4>
                </div>
              </div>
            </Link>
          </div>
        </div>
        </div>
        <div className="container">
        <div className="row">
        {auth.user.role === 0 || auth.user.role === 3 ? (
           <div className="shadow table-responsive customTable">
           <h2>Family List</h2>
           <table className="table">
             <thead>
               <tr>
                 <th>Family Name</th>
                 <th>Members</th>
                 <th>Booth No.</th>
               </tr>
             </thead>
             <tbody>
               {familyData.map((item, index) => (
                 <tr key={`${item.RLN_FM_NM_EN}-${index}`}>
                   <td onClick={() => navigate(`/family/${encodeURIComponent(item.RLN_FM_NM_EN)}`)} key={index}>{item.RLN_FM_NM_EN}</td>
                   <td>{item.count}</td>
                   <td>{item.PART_NO}</td>
                 </tr>
               ))}
             </tbody>

           </table>
         </div>
        ):
        (
        <div>
          {auth.user.role === 2 ? 
            ( <div className="shadow table-responsive customTable">
              <table className="table">
              <thead>
                <tr>
                  <th>Booth Number</th>
                  <th>Voters Count</th>
                </tr>
              </thead>
              <tbody>
                {boothData.map((item) => (
                  <tr key={item.PART_NO}>
                    <td onClick={() => navigate(`/village/${encodeURIComponent(item.PART_NO)}`)} >{item.PART_NO}</td>
                    <td>{item.count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
            ):""
          }
          </div>
        )
    
      
      }
        </div>
        </div>
      </Layout>
    );
  }


export default HomePage;
