import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import Store from './Redux/Store';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from './context/auth';
import "antd/dist/reset.css";
import { SearchProvider } from './context/search';
// import { CartProvider } from './context/cart';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={Store}>
    <AuthProvider>
      <SearchProvider>
        {/* <CartProvider> */}
        <BrowserRouter>
          <App />
        </BrowserRouter>
        {/* </CartProvider> */}
      </SearchProvider>
    </AuthProvider>
  </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
