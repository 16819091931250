import React, { useState, useEffect, useRef } from "react";
import Layout from "../components/layout/Layout";
import axios from "axios";
import { Link } from "react-router-dom";
import SearchInput from "../components/Form/SearchInput";
import { openDB } from "idb";
import Avatar from '@mui/material/Avatar';
import {useNavigate} from 'react-router-dom'
import { useAuth } from "../context/auth";

const SearchVoters = () => {
  const [auth] = useAuth();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [fetching, setFetching] = useState(false);
  const pageSize = 100;
  const containerRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        containerRef.current &&
        containerRef.current.scrollTop + containerRef.current.clientHeight >=
        containerRef.current.scrollHeight &&
        !fetching &&
        data.length < totalCount
      ) {
        setFetching(true);
      }
    };

    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [fetching, data.length, totalCount]);
  const authData = localStorage.getItem("auth");
  const authObject = JSON.parse(authData);
  const user = authObject.user;
  const [start, end] = user.partNoRanges;
  useEffect(() => {
    const fetchDataOnScroll = async () => {
      if (fetching) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };

    fetchDataOnScroll();
  }, [fetching]);

  // console.log(auth.user.partNoRanges[0]);
  const fetchData = async () => {
    setLoading(true);
    try {
      let fetchedData;
      let totalCount;
      if (navigator.onLine) {
        const response = await axios.get(
          `/api/v1/product/allvoter`,{
            params : {
              electionType: user.electionType,
              state: user.state,
              district: user.district,
              assemblyName: user.assembly,
              constituency: user.constituency,
              start: start,
              end: end
             }
          }
       
        );
        fetchedData = response.data;
        
        var dd = fetchedData.filter(u => u.Constituency === "Sarath");
        console.log(dd);
        totalCount = response.data.length;
      } else {
        const { data, totalCount: dbTotalCount } = await fetchDataFromIndexedDB(
          currentPage,
          pageSize
        );
        fetchedData = data;
        totalCount = dbTotalCount;
      }
      setData((prevData) => [...prevData, ...fetchedData]);
      setTotalCount(totalCount);
      setLoading(false);
      setFetching(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("An error occurred while fetching data.");
      setLoading(false);
      setFetching(false);
    }
  };

  const fetchDataFromIndexedDB = async (page, pageSize) => {
    try {
      const db = await openDB("rajasthan-voters-db", 1);
      if (!db.objectStoreNames.contains("votes")) {
        throw new Error("Object store 'votes' not found in the database.");
      }
      const tx = db.transaction("votes", "readonly");
      const store = tx.objectStore("votes");

      // Fetch data in smaller batches to improve performance
      const offset = (page - 1) * pageSize;
      const limit = pageSize;
      const data = await store.getAll(undefined, limit, offset);

      // Get total count of items in the store
      const totalCountRequest = store.count();
      const totalCount = await totalCountRequest;

      return { data, totalCount };
    } catch (error) {
      console.error("Error fetching data from IndexedDB:", error);
      throw error;
    }
  };

  const calculateSerialNumber = (index) => {
    return index + 1;
  };

  return (
    <Layout title={"All Voters"}>
      <div
        className="container mt-3"
        ref={containerRef}>
        <div className="row">
          <div className="col-md-12">
            <SearchInput />
            {loading && <h2 className="text-center">Loading...</h2>}
            {error && <h2 className="text-center">{error}</h2>}
            {data.length > 0 && (
              <>
                <p className="mt-2 text-light">Total number of voters: {totalCount}</p>
                <div className="listCards" style={{width:'100%', display:"flex", flexWrap:'wrap',columnGap:'10px'}}> 
                  {data.map((item, index) => { 
                    return (<div className="card card_container" key={index} onClick={() => navigate(`/voter/${encodeURIComponent(item.FM_NAME_EN)}`)}>
                        <div className="userAvatar" style={{width:'30%', display:'flex',justifyContent:'center'}}>
                          <Avatar alt={item.FM_NAME_EN} src="/static/images/avatar/1.jpg"sx={{ width: 70, height: 70 }} />
                        </div>
                        <div className="userdetails">
                          <h6 style={{margin:'0px'}}> {item.FM_NAME_EN} </h6>
                          <span>Sex : {item.GENDER}</span>
                          <span> Phone : {(item.MOBILE_NO != "") ? item.MOBILE_NO : "********"} </span>
                          <span>EPIC No : {item.EPIC_NO}</span>
                          <span> Age: {item.AGE}</span>

                        </div>
                        <div className="card-footer" style={{width:'100%'}}>
                          {item.voted ? (
                            <p className="d-flex-j">
                              {item.C_HOUSE_NO}
                              <input type="checkbox" checked={true} readOnly />
                            </p>
                          ) : (
                            <p className="d-flex-j">
                              {item.C_HOUSE_NO}
                              {item.fieldExists ? (
                                <input type="checkbox" checked={false} readOnly />
                              ) : (
                                <input type="checkbox" checked={false} disabled />
                              )}
                            </p>
                          )}
                        </div>
                    </div>
                  )})}
                </div>
              </>
            )}
            {data.length === 0 && !loading && (
              <h2 className="text-center text-light">No data found</h2>
            )}
          </div>
        </div>
      </div>
      {fetching && <h2 className="text-center text-light">Loading more...</h2>}
    </Layout>
  );
};

export default SearchVoters;
