import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Contact from "./pages/Contact";
import PageNotFond from "./pages/PageNotFond";
import Register from "./pages/Auth/Register";
import Login from "./pages/Auth/Login";
import PrivateRoute from "./components/Routes/Private";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import AdminRoute from "./components/Routes/AdminRoute";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import Users from "./pages/Admin/Users";
import Profile from "./pages/user/Profile";
import Dashboard from "./pages/user/Dashboard";
import Search from "./pages/Search";
import Query from "./pages/Admin/Query";
import Upload from "./pages/Admin/Upload";
import VoterDetails from "./pages/VoterDetails";
import VillageList from "./pages/VillageList";
import VillageVoterList from "./pages/VillageVoterList";
import SearchVoters from "./pages/SearchVoters";
import Check from "./pages/check";
import { useAuth } from "./context/auth";
import FamilyList from "./pages/FamilyList";
import FamilyVoters from "./pages/FamilyVoters";
import FilterdData from "./pages/Admin/FilterData";
import Confirm from "./pages/Confirm";
import AddQuestions from "./pages/Admin/AddQuestions";
import AddVoter from "./pages/AddVoter";


function App() {
  const [auth, setAuth] = useAuth()
  return (
    <>
      <Routes>
        {!auth?.user ? <Route path="/" element={<Login />} /> :
          <Route path="/" element={<HomePage/>} />
        }
          <Route path="/search" element={<Search />} />
          <Route path="/dashboard" element={<PrivateRoute />} >
          <Route path="user" element={<AdminDashboard />} />
          <Route path="user/users" element={<Users />} />
          <Route path="user/query" element={<Query />} />
          <Route path="user/upload" element={<Upload />} />
          <Route path="user/filteredData" element={<FilterdData />} />
          <Route path="user/addquestions" element={<AddQuestions />} />

        </Route>
        <Route path="/dashboard" element={<AdminRoute />}>
          <Route path="admin" element={<AdminDashboard />} />
          <Route path="admin/users" element={<Users />} />
          <Route path="admin/query" element={<Query />} />
          <Route path="admin/upload" element={<Upload />} />
          {/* <Route path="admin/addquestions" element={<AddQuestions />} /> */}

          <Route path="admin/filteredData" element={<FilterdData />} />
        </Route>
        <Route path="/confirm/:verificationToken" element={<Confirm />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forget-password" element={<ForgotPassword />} />
        {/* <Route path="/login" element={<Login />} /> */}
        <Route path="/home" element={<HomePage />} />
        <Route path="/addvoter" element={<AddVoter />} />
        <Route path="/voterList" element={<SearchVoters />} />
        <Route path="/voter/:slug" element={<VoterDetails />} />
        <Route path="/villages" element={<VillageList />} />
        <Route path="/family" element={<FamilyList />} />
        <Route path="/family/:slug" element={<FamilyVoters />} />
        <Route path="/check" element={<Check />} />
        <Route path="/village/:slug" element={<VillageVoterList />} />
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="*" element={<PageNotFond />} /> */}
      </Routes>
    </>
  );
}

export default App;
