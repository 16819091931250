import React, { useState } from "react";
import Layout from "../components/layout/Layout";
import "../style/Card.css";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Banner from '../Assets/banner.png';

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [query, setQuery] = useState("");
  const notyf = new Notyf();

  const navigate = useNavigate();

  const authData = localStorage.getItem("auth");
  const authObject = JSON.parse(authData);
  const user = authObject.user;
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`/api/v1/auth/contactus`, {
        name,
        email,
        phone,
        query,
        electionType: user.electionType,
        state: user.state,
        district: user.district,
        assemblyName: user.assembly,
        constituency: user.constituency
      });
      if (res.data.success) {
        notyf.success(res.data.message);
        navigate("/home");
      } else {
        notyf.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      notyf.error("Something went wrong");
    }
  };

  return (
    <Layout title={"Contact us - Voters App"}>
      <div className="container">
        <h3 className="text-light">Contact Us</h3>
        <div className="contact_form_container d-flex">
          <div className="contact_form_img w-50">
            <img src={Banner} alt="" width={'100%'}  />
          </div>
          <div className="contact_form w-50">
            <div className="form-container1">
              
              <form className="form" onSubmit={handleSubmit}>
              <div className="w-100 mb-4"><h5><strong>Contact Us</strong></h5></div>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="form-control"
                  placeholder="Your Name"
                  name="Name"
                  type="text"
                  required
                />
                <input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="form-control"
                  placeholder="Your Mobile Number"
                  name="Phone"
                  type="tel"
                  required
                />
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                  placeholder="Your Email address"
                  name="Email"
                  type="email"
                  required
                />
                <textarea
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  className="form-control"
                  placeholder="Your Query"
                  name="Query"
                  rows="4"
                  required
                />
                <button className="btn btn-primary submitBtn" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* <div className="col-md-12 mt-5">
            <div className="map-container">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.237728630585!2d77.41069697465917!3d28.50249447573594!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce910b1acf48b%3A0x58ac43aea0e28990!2sNukleus%20Coworking%20%26%20Managed%20Offices!5e0!3m2!1sen!2sin!4v1710998543659!5m2!1sen!2sin"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div> */}
      </div>
    </Layout>
  );
};

export default Contact;
