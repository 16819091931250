import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useSearch } from "../context/search";
import Layout from "../components/layout/Layout";

const Search = () => {
  const [values] = useSearch(); // Get values from context

  return (
    <Layout title={"All Voters"}>
      <div className="container-fluid mt-3">
        <div className="row">
          {values.loading ? (
            <div className="col-md-12 text-center">
              <h2>Loading...</h2>
            </div>
          ) : values.error ? (
            <div className="col-md-12 text-center">
              <h2>{values.error}</h2>
            </div>
          ) : (
            <div className="col-md-12">
              <h4 className="text-light">
                Total number of items:{" "}
                {values.results ? values.results.length : 0}
              </h4>
              <div className="border shadow table-responsive">
                <table className="table table-hover table-striped">
                  <thead>
                    <tr>
                      <th scope="col">S.No.</th>
                      <th scope="col">Name</th>
                      <th scope="col">Village</th>
                      <th scope="col">EPIC No.</th>
                    </tr>
                  </thead>
                  <tbody>
                    {values.results.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <Link
                            to={`/voter/${encodeURIComponent(item.FM_NAME_EN)}`}
                          >
                            {item.FM_NAME_EN}
                          </Link>
                        </td>
                        <td>{item.C_HOUSE_NO}</td>
                        <td>{item.EPIC_NO}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Search;
