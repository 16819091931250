import React, { useState } from "react";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import axios from "axios";
import ReactAudioPlayer from 'react-audio-player';

const DataModal = ({ isOpen, onClose, data, userId, fetchData }) => {
  const notyf = new Notyf();
  const [isLoading, setIsLoading] = useState(false);

  const updateData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.put(`/api/v1/category/mergdata/${userId}`, data);
      if (response.status === 200) {
        notyf.success('Data updated successfully');
        onClose(); // Close the modal on success
      } else {
        notyf.error(response.data.message || 'Failed to update data');
      }
    } catch (error) {
      notyf.error('Network error');
      console.error('Update Data Error:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const deleteData = async (id) => {
    try {
      // Delete the data and check the response status
      const response = await axios.delete(`/api/v1/category/delete/${id}`);
  
      // Handle success case
      if (response.status === 200) {
        notyf.success('Data deleted successfully');
  
        // Try fetching data in a separate try-catch block to prevent error propagation
        try {
          await fetchData(); // Refresh the data after deletion
        } catch (fetchError) {
          console.error('Error fetching updated data:', fetchError);
          // notyf.error('Error fetching updated data');
        }
      } else {
        notyf.error('Failed to delete data');
      }
    } catch (error) {
      console.error('Delete Data Error:', error);
      notyf.error('Error deleting data');
    }
  };
  

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal show" style={{ display: 'block' }} role="dialog">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content mt-5">
          <div className="modal-header">
            <h5 className="modal-title">Uploaded by {data && data.length > 0 ? data[0].uploadedByName : 'Unknown'}</h5>
            <button type="button" className="close" onClick={onClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {data && data.length > 0 ? (
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>PART_NO</th>
                      <th>SECTION_NO</th>
                      <th>SLNOINPART</th>
                      <th>C_HOUSE_NO</th>
                      <th>LASTNAME_EN</th>
                      <th>EPIC_NO</th>
                      <th>Gender</th>
                      <th>AGE</th>
                      <th>MOBILE_NO</th>
                      <th>D.O.B</th>
                      <th>Died</th>
                      <th>Survey</th>
                      <th>Voted</th>
                      <th>Recording</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr key={index}>
                        <td>{item.FM_NAME_EN}</td>
                        <td>{item.PART_NO}</td>
                        <td>{item.SECTION_NO}</td>
                        <td>{item.SLNOINPART}</td>
                        <td>{item.C_HOUSE_NO}</td>
                        <td>{item.LASTNAME_EN}</td>
                        <td>{item.EPIC_NO}</td>
                        <td>{item.GENDER}</td>
                        <td>{item.AGE}</td>
                        <td>{item.MOBILE_NO}</td>
                        <td>{item.DOB}</td>
                        <td>{item.died ? "Yes" : "No"}</td>
                        <td>{item.surveyOption}</td>
                        <td>{item.voted ? "Yes" : "No"}</td>
                        <td>
                          {item.filename ? (
                            <ReactAudioPlayer
                              src={`/api/v1/product/audio/${item.filename.split('/').pop()}`}
                              controls
                            />
                          ) : (
                            "No audio available"
                          )}
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => deleteData(item._id)} // Ensure that _id is passed correctly
                          >
                            Delete
                          </button>

                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p>No data found.</p>
            )}
          </div>
          <div className="modal-footer">
            {data && data.length > 0 && (
              <button type="button" className="btn btn-primary" onClick={updateData} disabled={isLoading}>
                {isLoading ? 'Updating...' : 'Update Data'}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataModal;
