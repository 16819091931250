import React, { createContext, useContext, useState } from "react";

// Create the context
const SearchContext = createContext();

// Create a provider component
export const SearchProvider = ({ children }) => {
  const [searchState, setSearchState] = useState({
    keyword: "",
    results: [] // Ensure results is initialized as an array
  });

  return (
    <SearchContext.Provider value={[searchState, setSearchState]}>
      {children}
    </SearchContext.Provider>
  );
};

// Custom hook to use the search context
export const useSearch = () => useContext(SearchContext);
