import React, { useState, useEffect, useRef } from "react";
import Layout from "../components/layout/Layout";
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';
import "../style/Card.css";
import { useDispatch, useSelector } from 'react-redux';
import { getAllVoters } from '../Redux/Actions/getAllvoters';

const Check = ({ electionType, state, district, assemblyName, constituency, start, end }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({ AllVoterData: [], totalCount: 0 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [fetching, setFetching] = useState(false);
  const itemsPerPage = 100; // Adjusted to match backend
  const containerRef = useRef(null);
  const localData = JSON.parse(localStorage.getItem('allData')) || [];

  useEffect(() => {
    // Set initial data from local storage
    setData({ AllVoterData: localData, totalCount: localData.length });
    setLoading(false); // Assuming data is loaded from local storage, no need to fetch more
  }, [localData]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        containerRef.current &&
        containerRef.current.scrollTop + containerRef.current.clientHeight >=
        containerRef.current.scrollHeight &&
        !fetching &&
        data.AllVoterData.length < data.totalCount
      ) {
        setFetching(true);
      }
    };

    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [fetching, data.AllVoterData.length, data.totalCount]);

  useEffect(() => {
    if (fetching) {
      fetchData();
    }
  }, [fetching]);

  const fetchData = async () => {
    setLoading(true);
    try {
      // Uncomment and configure these lines if you are fetching from an API
      // const response = await axios.get('/api/v1/product/allvotes', {
      //   params: {
      //     electionType,
      //     state,
      //     district,
      //     assemblyName,
      //     constituency,
      //     start,
      //     end,
      //   },
      // });
      // const fetchedData = response.data.data;
      // const totalCount = fetchedData.length;

      // For demonstration, we'll use local data and a delay
      const fetchedData = localData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
      const totalCount = localData.length;

      setData(prevData => ({
        AllVoterData: [...prevData.AllVoterData, ...fetchedData],
        totalCount
      }));
      setLoading(false);
      setFetching(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("An error occurred while fetching data.");
      setLoading(false);
      setFetching(false);
    }
  };

  return (
    <Layout title={"Voters App"}>
      <div className="container" ref={containerRef}>
        <div className="row">
          <div className="col-md-12">
            {loading && !data.AllVoterData.length && <h2 className="text-center">Loading...</h2>}
            {error && <h2 className="text-center">{error}</h2>}
          </div>
          {data.totalCount > 0 && (
            <>
              <p className="mt-2">Total number of items: {data.totalCount}</p>
              <div className="listCards" style={{ width: '100%', display: "flex", flexWrap: 'wrap', columnGap: '10px' }}>
                {data.AllVoterData.map((item, index) => (
                  <div className="card card_container" key={index} onClick={() => navigate(`/voter/${encodeURIComponent(item.EPIC_NO)}`)}>
                    <div className="userAvatar" style={{ width: '30%', display: 'flex', justifyContent: 'center' }}>
                      <Avatar alt={item.FM_NAME_EN} src="/static/images/avatar/1.jpg" sx={{ width: 70, height: 70 }} />
                    </div>
                    <div className="userdetails">
                      <h6 style={{ margin: '0px' }}> {item.FM_NAME_EN} </h6>
                      <span>Sex: {item.GENDER}</span>
                      <span>Phone: {(item.MOBILE_NO !== "") ? item.MOBILE_NO : "********"} </span>
                      <span>EPIC No: {item.EPIC_NO}</span>
                      <span>Age: {item.AGE}</span>
                    </div>
                    <div className="card-footer" style={{ width: '100%' }}>
                      {item.voted ? (
                        <p className="d-flex-j">
                          {item.C_HOUSE_NO}
                          <input type="checkbox" checked={true} readOnly />
                        </p>
                      ) : (
                        <p className="d-flex-j">
                          {item.C_HOUSE_NO}
                          {item.fieldExists ? (
                            <input type="checkbox" checked={false} readOnly />
                          ) : (
                            <input type="checkbox" checked={false} disabled />
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          {!loading && data.AllVoterData.length === 0 && (
            <h2 className="text-center">No data found</h2>
          )}
        </div>
      </div>
      {fetching && <h2 className="text-center">Loading more...</h2>}
    </Layout>
  );
};

export default Check;
