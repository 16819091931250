import { createStore,applyMiddleware } from 'redux';
import {thunk} from 'redux-thunk'
import {Get_All_VotersReducer} from './Reducers/AllVotersReducer';

const store = createStore(
    Get_All_VotersReducer,
    applyMiddleware(thunk),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
