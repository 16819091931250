import React from "react";
import { styled } from '@mui/material/styles';
import { useAuth } from "../../context/auth";
import Header from '../../components/layout/Header2';
import DrawerNav from "../../components/layout/Drawer";
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Register from "../Auth/Register";


const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function AdminDashboard() {
  const [auth] = useAuth();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false);
  };

  // console.log(rows);
  return (
    <Box sx={{ display: 'flex' }}>
      <Header handleDrawerOpen={handleDrawerOpen} open={open} title={'Dashboard'} />
      <DrawerNav open={open} handleDrawerClose={handleDrawerClose} theme={theme} />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Register />
      </Box>
    </Box>
  );
}

export default AdminDashboard;