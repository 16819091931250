import * as React from 'react';
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import Logo from '../../Assets/janLogo.png';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import {useNavigate} from 'react-router-dom';
import { useAuth } from "../../context/auth";

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });
  
  const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }),
  );

  

export default function DrawerNav({handleDrawerClose,open,theme}){
    const navigate = useNavigate();
    const [auth] = useAuth();
    const {role} = auth?.user;
    const navItems = [
       
      {
        title: role === 1 ? 'Add Client' : (role === 2 ? 'Add User' : ''),
          icon:<AddCircleOutlineIcon />,
          url:`/dashboard/${role === 1 ? 'admin' : 'user'}`
      },
      {
          title:'Add Data',
          icon:<AddCircleOutlineIcon />,
          url:`/dashboard/${role === 1 ? 'admin' : 'user'}/upload`
      },
      {
          title:'All Users',
          icon:<ContactsOutlinedIcon />,
          url:`/dashboard/${role === 1 ? 'admin' : 'user'}/users`
      },
      {
          title:'Query',
          icon:<QueryBuilderIcon />,
          url:`/dashboard/${role === 1 ? 'admin' : 'user'}/query`
      },
      {
          title:'Filtered Data',
          icon:<FilterAltOutlinedIcon />,
          url:`/dashboard/${role === 1 ? 'admin' : 'user'}/filteredData`
      },
      (role ===1 ?" ":({
        title:'Add Questions',
        icon:<QuestionMarkIcon />,
        url:`/dashboard/${role === 1 ? 'admin' : 'user'}/addquestions`
    }))
    ]
    return <Drawer variant="permanent" open={open}>
    <DrawerHeader className='justify-content-between'>
      {/* <img src={Logo} alt="logo" width={'100px'} /> */}
      <IconButton onClick={handleDrawerClose}>
        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </IconButton>
    </DrawerHeader> 
    <Divider />
    <List>
      {navItems.map((text, index) => {
        if(text.title === 'Add Data'){
          if(role === 1){
            return (
              <ListItem key={index} disablePadding sx={{ display: 'block' }} onClick={() => navigate(text.url)}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {text.icon}
                  </ListItemIcon>
                  <ListItemText primary={text.title} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            )
          }
        }else{
            return (
                <ListItem key={index} disablePadding sx={{ display: 'block' }} onClick={() => navigate(text.url)}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText primary={text.title} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              )
        }
        })}
    </List>
  </Drawer>
}