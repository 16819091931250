// import React, { useState, useEffect } from 'react';
// import AdminMenu from '../../components/layout/AdminMenu';
// import Layout from "../../components/layout/Layout";
// import axios from 'axios';
// import { Notyf } from 'notyf';
// import 'notyf/notyf.min.css';
// import Header from '../../components/layout/Header2';
// import DrawerNav from "../../components/layout/Drawer";
// import { useTheme, styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';


// const DrawerHeader = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'flex-end',
//   padding: theme.spacing(0, 1),
//   // necessary for content to be below app bar
//   ...theme.mixins.toolbar,
// }));
// const Query = () => {
//   const [queries, setQueries] = useState([]);
//   const notyf = new Notyf();
//   const theme = useTheme();
//   const [open, setOpen] = React.useState(false);

//   const handleDrawerOpen = () => {
//     setOpen(true)
//   }
//   const handleDrawerClose = () => {
//     setOpen(false);
//   };

//   const authData = localStorage.getItem("auth");
//   const authObject = JSON.parse(authData);
//   const user = authObject.user;

//   const getQueries = async () => {
//     try {
//       const { data } = await axios.get(`/api/v1/category/queries`,{
//         params: {
//           electionType: user.electionType,
//           state: user.state,
//           district: user.district,
//           assemblyName: user.assembly,
//           constituency: user.constituency
//         }
//       });
//    console.log(data)
//     } catch (error) {
//       console.log(error);
//       notyf.error("Something went wrong");
//     }
//   };

//   useEffect(() => {
//     getQueries();
//   }, []);

//   const handleStatusChange = async (id, status) => {
//     try {
//       await axios.put(`/api/v1/category/queries/${id}`, { status });
//       // Update local state to reflect the changed status
//       setQueries(prevQueries =>
//         prevQueries.map(query =>
//           query._id === id ? { ...query, status } : query
//         )
//       );
//     } catch (error) {
//       console.log(error);
//       notyf.error("Failed to update status");
//     }
//   };

//   return (

//     <Box sx={{ display: 'flex' }}>
//       <Header handleDrawerOpen={handleDrawerOpen} open={open} title={'Users Query'} />
//       <DrawerNav open={open} handleDrawerClose={handleDrawerClose} theme={theme} />
//       <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
//         <DrawerHeader />
//         <div className="border shadow table-responsive customTable">
//           <table className="table">
//             <thead>
//               <tr>
//                 <th scope="col">S. No.</th>
//                 <th scope="col">Name</th>
//                 <th scope="col">Number</th>
//                 <th scope="col">Email</th>
//                 <th scope="col">Concern</th>
//                 <th scope="col">Status</th>
//               </tr>
//             </thead>
//             <tbody>
//               {queries.map((q, i) => (
//                 <tr key={q._id}>
//                   <td>{i + 1}</td>
//                   <td>{q.name}</td>
//                   <td>{q.phone}</td>
//                   <td>{q.email}</td>
//                   <td>{q.query}</td>
//                   <td>
//                     <select
//                       className='custom_selector'
//                       value={q.status}
//                       onChange={(e) => handleStatusChange(q._id, e.target.value)}
//                     >
//                       <option value="open">Open</option>
//                       <option value="closed">Closed</option>
//                     </select>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </Box>
//     </Box>
//   );
// };

// export default Query;
import React, { useState, useEffect } from 'react';
import AdminMenu from '../../components/layout/AdminMenu';
import Layout from "../../components/layout/Layout";
import axios from 'axios';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import Header from '../../components/layout/Header2';
import DrawerNav from "../../components/layout/Drawer";
import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';


const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const Query = () => {
  const [queries, setQueries] = useState([]);
  const notyf = new Notyf();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const authData = localStorage.getItem("auth");
  const authObject = JSON.parse(authData);
  const user = authObject.user;
  const [start, end] = user.partNoRanges;

  const getQueries = async () => {
    try {
      const { data } = await axios.get(`/api/v1/category/queries`,{
        params: {
          electionType: user.electionType,
          state: user.state,
          district: user.district,
          assemblyName: user.assembly,
          constituency: user.constituency,
          start: start,
          end: end
        }
      });
      setQueries(data);
    } catch (error) {
      console.log(error);
      notyf.error("Something went wrong");
    }
  };

  useEffect(() => {
    getQueries();
  }, []);

  const handleStatusChange = async (id, status) => {
    try {
      await axios.put(`/api/v1/category/queries/${id}`, { status });
      // Update local state to reflect the changed status
      setQueries(prevQueries =>
        prevQueries.map(query =>
          query._id === id ? { ...query, status } : query
        )
      );
    } catch (error) {
      console.log(error);
      notyf.error("Failed to update status");
    }
  };

  return (

    <Box sx={{ display: 'flex' }}>
      <Header handleDrawerOpen={handleDrawerOpen} open={open} title={'Users Query'} />
      <DrawerNav open={open} handleDrawerClose={handleDrawerClose} theme={theme} />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <div className="border shadow table-responsive customTable">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">S. No.</th>
                <th scope="col">Name</th>
                <th scope="col">Number</th>
                <th scope="col">Email</th>
                <th scope="col">Concern</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {queries.map((q, i) => (
                <tr key={q._id}>
                  <td>{i + 1}</td>
                  <td>{q.name}</td>
                  <td>{q.phone}</td>
                  <td>{q.email}</td>
                  <td>{q.query}</td>
                  <td>
                    <select
                      className='custom_selector'
                      value={q.status}
                      onChange={(e) => handleStatusChange(q._id, e.target.value)}
                    >
                      <option value="open">Open</option>
                      <option value="closed">Closed</option>
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Box>
    </Box>
  );
};

export default Query;
