import React from 'react'
import Header from './Header'

import { Helmet } from "react-helmet";
import { Toaster } from 'react-hot-toast';

const Layout = ({ children, title, description, keywords, author }) => {
  return (
    <div>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="author" content={author} />
        <title>{title}</title>
      </Helmet>

      <Header />
      <main>
        {children}
        <Toaster />
      </main>

    </div>
  );
};

Layout.defaultProps = {
  title: 'Voter App',
  description: 'Mern Project',
  keywords: 'shop,react ,mern'
}

export default Layout;
