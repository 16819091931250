import React, { useState } from "react";
import { useSearch } from "../../context/search";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Datetime from "react-datetime";
import { useAuth } from "../../context/auth";

const SearchInput = () => {
  const [auth] = useAuth();
  const [values, setValues] = useSearch();
  const [dob, setDob] = useState(null);
  const [epicNo, setEpicNo] = useState("");
  const navigate = useNavigate();

  const authData = localStorage.getItem("auth");
  const authObject = JSON.parse(authData);
  const user = authObject.user;
  const [start, end] = user.partNoRanges;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const params = new URLSearchParams();
      if (values.keyword.trim()) {
        params.append("searchTerm", values.keyword);
      }
      if (dob) {
        params.append("dob", dob.toISOString().split("T")[0]); // Format the date as YYYY-MM-DD
      }
      if (epicNo.trim()) {
        params.append("epicNo", epicNo);
      }

      if (!params.toString()) {
        console.error("At least one search term must be provided");
        return;
      }

      const { data } = await axios.get(`/api/v1/product/search`,{
        params : {
          electionType: user.electionType,
          state: user.state,
          district: user.district,
          assemblyName: user.assembly,
          constituency: user.constituency,
          start: start,
          end: end
         }
      });
      // Ensure data is an array before setting it
      if (Array.isArray(data)) {
        setValues({ ...values, results: data });
      } else {
        console.error("Unexpected response format:", data);
      }
      navigate("/search");
    } catch (error) {
      console.log(error);
    }
  };

  const handleDateChange = (date) => {
    if (date && typeof date.toDate === 'function') {
      setDob(date.toDate());
    } else {
      setDob(null);
    }
  };

  const validDate = (current) => {
    const eighteenYearsAgo = new Date();
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
    return current.isBefore(eighteenYearsAgo);
  };

  return (
    <div className="">
      <form role="search" className="row" onSubmit={handleSubmit}>
        <div className="col-sm search_input_cantainer">
          <input
            className="form-control me-2 m-0"
            type="search"
            placeholder="Search by Name"
            aria-label="Search"
            value={values.keyword}
            onChange={(e) => setValues({ ...values, keyword: e.target.value })}
          />
        </div>
        <div className="col-sm search_input_cantainer">
          <input
            className="form-control me-2 m-0"
            type="text"
            placeholder="Search by EPIC No"
            aria-label="Search"
            value={epicNo}
            onChange={(e) => setEpicNo(e.target.value)}
          />
        </div>
        <div className="col-sm search_input_cantainer">
          <Datetime
            inputProps={{
              id: "dob",
              className: "form-control",
              placeholder: "Search by DOB (YYYY-MM-DD)"
            }}
            value={dob || undefined}
            onChange={handleDateChange}
            dateFormat="YYYY-MM-DD"
            timeFormat={false}
            isValidDate={validDate}
          />
        </div>
        <div className="col-sm">
          <button className="btn btn-primary form-control" type="submit">
            Search
          </button>
        </div>

      </form>
    </div>
  );
};

export default SearchInput;
