import React, { useState, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import axios from "axios";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import AdminMenu from "../../components/layout/AdminMenu";
import DataModal from './DataModal'; // Import the modal component
import "../../style/Card.css";
import { useParams } from "react-router-dom";
import Header from '../../components/layout/Header2';
import DrawerNav from "../../components/layout/Drawer";
import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function Users() {
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [uploadedData, setUploadedData] = useState(null);
  const [activeUserId, setActiveUserId] = useState(null);
  const notyf = new Notyf();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  console.log(users);
  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const authData = localStorage.getItem("auth");
  const authObject = JSON.parse(authData);
  const user = authObject.user;
  const [start, end] = user.partNoRanges;
  const getUsers = async () => {
    try {
      const { data } = await axios.get(`/api/v1/category/users`,{
      params: {
          electionType: user.electionType,
          state: user.state,
          district: user.district,
          assemblyName: user.assembly,
          constituency: user.constituency,
          start: start,
          end: end
        }
      }
      );
      setUsers(data);
    } catch (error) {
      console.log(error);
      notyf.error("Something went wrong");
    }
  };
  // const token = useAuthToken();
  function getTokenFromLocalStorage() {
    const authDataString = localStorage.getItem('auth');
    if (authDataString) {
      const authData = JSON.parse(authDataString);
      return authData.token;
    }
    return null;
  }

  const token = getTokenFromLocalStorage();

  const updateUserRole = async (userId, newRole) => {
    try {
      await axios.put(`/api/v1/category/users/${userId}/role`, { role: Number(newRole) });
      const updatedUsers = users.map(user => {
        if (user._id === userId) {
          return { ...user, role: newRole };
        }
        return user;
      });
      setUsers(updatedUsers);
      notyf.success("User role updated successfully");
    } catch (error) {
      console.error(error);
      notyf.error("Failed to update user role");
    }
  };

  const viewUploadedData = async (userId) => {
    try {
      const response = await axios.get(`/api/v1/category/staged-by-user/${userId}`);
      const unprocessedData = response.data.filter(item => !item.processed); // Filter out processed entries
      setUploadedData(unprocessedData);
      setActiveUserId(userId); // Set active user ID
      setShowModal(true);
    } catch (error) {
      console.error("Error fetching uploaded data:", error);
      notyf.error("Failed to fetch uploaded data");
    }
  };


  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    getUsers();
    console.log(token); // Just for debugging, can be removed later
  }, []);

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Header handleDrawerOpen={handleDrawerOpen} open={open} title={'All Users'} />
        <DrawerNav open={open} handleDrawerClose={handleDrawerClose} theme={theme} />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          <div className="shadow table-responsive customTable">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">S.No.</th>
                  <th scope="col">Name</th>
                  <th scope="col">Number</th>
                  <th scope="col">Email</th>
                  <th scope="col">Role</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => (
                  <tr key={user._id}>
                    <td>{index + 1}</td>
                    <td>{user.name}</td>
                    <td>{user.phone}</td>
                    <td>{user.email}</td>
                    <td>
                      <select
                        className="custom_selector"
                        value={user.role}
                        onChange={(e) => updateUserRole(user._id, e.target.value)}
                      >
                        <option value={0}>User</option>
                        <option value={1}>Admin</option>
                        <option value={2}>Sub-admin</option>
                      </select>
                    </td>
                    <td>
                      <a className="text-decoration-underline cursor-pointer" onClick={() => viewUploadedData(user._id, user.FM_NAME_EN)}>View Uploaded Data</a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Box>
      </Box>
      <DataModal isOpen={showModal} onClose={closeModal} data={uploadedData} userId={activeUserId} />
    </>
  );
}

export default Users;
