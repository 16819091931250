import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout/Layout';
import AdminMenu from '../../components/layout/AdminMenu';
import axios from 'axios';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import { CSVLink } from "react-csv";
import Header from '../../components/layout/Header2';
import DrawerNav from "../../components/layout/Drawer";
import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

const notyf = new Notyf();

const FilteredData = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [voted, setVoted] = useState([]);
    const [died, setDied] = useState([]);
    const [ourVoter, setOurVoters] = useState([]);
    const [oppositeVoter, setOppVoters] = useState([]);
    const [doubtVoter, setDoubtVoters] = useState([]);
    const [other, setOtherVoters] = useState([]);
    const [category, setCategory] = useState('Ours');
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
  
    const handleDrawerOpen = () => {
      setOpen(true)
    }
    const handleDrawerClose = () => {
      setOpen(false);
    };
    const handleTabClick = async (tabIndex) => {
        setActiveTab(tabIndex);
        if (tabIndex === 0) {
            if (voted.length === 0) {
                await getVoted();
            }
        } else if (tabIndex === 1) {
            if (died.length === 0) {
                await getDied();
            }
        } else if (tabIndex === 2) {
            handleCategoryChange(category);
        }
    };

    const handleCategoryChange = async (category) => {
        setCategory(category);
        switch (category) {
            case 'Ours':
                if (ourVoter.length === 0) {
                    await getOurvoters();
                }
                break;
            case 'Opposite':
                if (oppositeVoter.length === 0) {
                    await getOppositeVoters();
                }
                break;
            case 'Doubt':
                if (doubtVoter.length === 0) {
                    await getDoubtVoters();
                }
                break;
            case 'Others':
                if (other.length === 0) {
                    await getOtherVoters();
                }
                break;
            default:
                break;
        }
    };

    const getVoted = async () => {
        try {
            const { data } = await axios.get(`/api/v1/category/voted`);
            setVoted(data);
        } catch (error) {
            console.error(error);
            notyf.error("Failed to fetch voted data.");
        }
    };

    const getDied = async () => {
        try {
            const { data } = await axios.get(`/api/v1/category/diedvoter`);
            if (Array.isArray(data)) {
                setDied(data);
            } else {
                console.error("Data received for 'died' is not an array.");
                notyf.error("Failed to fetch died data.");
            }
        } catch (error) {
            console.error(error);
            notyf.error("Failed to fetch died data.");
        }
    };

    const getOurvoters = async () => {
        try {
            const { data } = await axios.get(`/api/v1/category/ourvoter`);
            if (Array.isArray(data)) {
                setOurVoters(data);
            } else {
                console.error("Data received for 'died' is not an array.");
                notyf.error("Failed to fetch our voters list");
            }
        } catch (error) {
            console.error(error);
            notyf.error("Failed to fetch Our Supporters data.");
        }
    }

    const getOppositeVoters = async () => {
        try {
            const { data } = await axios.get('/api/v1/category/oppositevoter');
            if (Array.isArray(data)) {
                setOppVoters(data);
            } else {
                console.error("Data received for Opposite is not an array.");
                notyf.error("Failed to fetch Opposite voters list");
            }
        } catch (error) {
            console.error(error);
            notyf.error("Failed to fetch Opposite Supporters data.");
        }
    }

    const getDoubtVoters = async () => {
        try {
            const { data } = await axios.get('/api/v1/category/doubtvoter');
            if (Array.isArray(data)) {
                setDoubtVoters(data);
            } else {
                console.error("Data received for Doubt Voters is not an array.");
                notyf.error("Failed to fetch Opposite voters list");
            }
        } catch (error) {
            console.error(error);
            notyf.error("Failed to fetch Doubt Voters data.");
        }
    }

    const getOtherVoters = async () => {
        try {
            const { data } = await axios.get('/api/v1/category/othervoters');
            if (Array.isArray(data)) {
                setOtherVoters(data);
            } else {
                console.error("Data received for Other Voters is not an array.");
                notyf.error("Failed to fetch Other voters list");
            }
        } catch (error) {
            console.error(error);
            notyf.error("Failed to fetch Other Voters data.");
        }
    }

    useEffect(() => {
        handleTabClick(activeTab);
    }, [activeTab]); // Fetch data when activeTab changes

    useEffect(() => {
        handleCategoryChange(category);
    }, [category]); // Fetch data when category changes

    const renderVoters = () => {
        switch (category) {
            case 'Ours':
                return ourVoter.map((q, i) => (
                    <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{q.FM_NAME_EN}</td>
                        <td>{q.MOBILE_NO}</td>
                        <td>{q.EPIC_NO}</td>
                        <td>{q.RLN_FM_NM_EN}</td>
                    </tr>
                ));
            case 'Opposite':
                return oppositeVoter.map((q, i) => (
                    <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{q.FM_NAME_EN}</td>
                        <td>{q.MOBILE_NO}</td>
                        <td>{q.EPIC_NO}</td>
                        <td>{q.RLN_FM_NM_EN}</td>
                    </tr>
                ));
            case 'Doubt':
                return doubtVoter.map((q, i) => (
                    <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{q.FM_NAME_EN}</td>
                        <td>{q.MOBILE_NO}</td>
                        <td>{q.EPIC_NO}</td>
                        <td>{q.RLN_FM_NM_EN}</td>
                    </tr>
                ));
            case 'Others':
                return other.map((q, i) => (
                    <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{q.FM_NAME_EN}</td>
                        <td>{q.MOBILE_NO}</td>
                        <td>{q.EPIC_NO}</td>
                        <td>{q.RLN_FM_NM_EN}</td>
                    </tr>
                ));
            default:
                return null;
        }
    };


    // Function to generate CSV data
    const generateCSVData = () => {
        let data;
        switch (category) {
            case 'Ours':
                data = ourVoter;
                break;
            case 'Opposite':
                data = oppositeVoter;
                break;
            case 'Doubt':
                data = doubtVoter;
                break;
            case 'Others':
                data = other;
                break;
            default:
                data = [];
        }

        if (data.length === 0) return [];

        // Get all keys (fields) from the first item
        const keys = Object.keys(data[0]);

        // Generate CSV data
        const csvData = data.map(item => {
            const csvItem = {};
            keys.forEach(key => {
                // Use field name as CSV header
                csvItem[key] = item[key];
            });
            return csvItem;
        });

        return csvData;
    };


    const generateCSVDataTabs = (data) => {
        if (data.length === 0) return [];

        const keys = Object.keys(data[0]);

        // Generate CSV data
        const csvData = data.map(item => {
            const csvItem = {};
            keys.forEach(key => {
                // Use field name as CSV header
                csvItem[key] = item[key];
            });
            return csvItem;
        });

        return csvData;
    }



    return (
        <Box sx={{ display: 'flex' }}>
      <Header handleDrawerOpen={handleDrawerOpen} open={open} title={'Users Query'} />
      <DrawerNav open={open} handleDrawerClose={handleDrawerClose} theme={theme} />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <div className="shadow1">
                        <ul className="nav nav-tabs">
                            <li className="nav-item tab_btn">
                                <button className={`nav-link ${activeTab === 0 ? 'active' : ''}`} onClick={() => handleTabClick(0)}>Voted</button>
                            </li>
                            <li className="nav-item tab_btn">
                                <button className={`nav-link ${activeTab === 1 ? 'active' : ''}`} onClick={() => handleTabClick(1)}>Died</button>
                            </li>
                            <li className="nav-item tab_btn">
                                <button className={`nav-link ${activeTab === 2 ? 'active' : ''}`} onClick={() => handleTabClick(2)}>Survey</button>
                            </li>
                        </ul>

                        <div className="tab-content">
                            <div className={`table-responsive customTable tab-pane ${activeTab === 0 ? 'active' : ''}`}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">S. No.</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Number</th>
                                            <th scope="col">EPIC no.</th>
                                            <th scope="col">Family</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {voted.map((q, i) => (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{q.FM_NAME_EN}</td>
                                                <td>{q.MOBILE_NO}</td>
                                                <td>{q.EPIC_NO}</td>
                                                <td>{q.RLN_FM_NM_EN}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <CSVLink data={voted} filename="voted_data.csv">Download CSV</CSVLink>
                            </div>
                            <div className={`table-responsive customTable tab-pane ${activeTab === 1 ? 'active' : ''}`}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">S. No.</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Number</th>
                                            <th scope="col">EPIC no.</th>
                                            <th scope="col">Family</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {died.map((q, i) => (
                                            <tr key={i}>
                                                <td>{i + 1}</td>
                                                <td>{q.FM_NAME_EN}</td>
                                                <td>{q.MOBILE_NO}</td>
                                                <td>{q.EPIC_NO}</td>
                                                <td>{q.RLN_FM_NM_EN}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <CSVLink data={died} filename="died_data.csv">Download CSV</CSVLink>
                            </div>
                            <div className={`table-responsive customTable tab-pane ${activeTab === 2 ? 'active' : ''}`}>
                                <div className="d-flex justify-content-end mb-3">
                                    <select onChange={(e) => handleCategoryChange(e.target.value)} className=''>
                                        <option value="Ours">Ours</option>
                                        <option value="Opposite">Against</option>
                                        <option value="Doubt">Doubt</option>
                                        <option value="Others">Others</option>
                                    </select>
                                </div>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">S. No.</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Number</th>
                                            <th scope="col">EPIC no.</th>
                                            <th scope="col">Family</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {renderVoters()}
                                    </tbody>
                                </table>
                                <CSVLink data={generateCSVData()} filename="voter_data.csv">Download CSV</CSVLink>
                            </div>
                        </div>
                    </div>
    </Box>
</Box>

        // <Layout>
        //     <div className="container-fluid mt-3 p-3">
        //         <div className="row">
        //             <div className="col-md-3">
        //                 <AdminMenu />
        //             </div>
                    
        //         </div>
        //     </div>
        // </Layout>
    );
}

export default FilteredData;
