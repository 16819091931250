import React, { useState } from "react";
// import Layout from "../../components/layout/Layout";
import "../../style/Register.css";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";
// import Logo from "../../Assets/JanAdharlogo.png"
import {useDispatch } from 'react-redux';
import {getAllVoters} from '../../Redux/Actions/getAllvoters';

const Login = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const notyf = new Notyf();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`/api/v1/auth/login`, { email, password });
      if (res.data.success) {
        notyf.success(res.data.message);
        setAuth({
          ...auth,
          user: res.data.user,
          token: res.data.token,
        });
        
        localStorage.setItem("auth", JSON.stringify(res.data));
        dispatch(getAllVoters());
        console.log(res.data.user.role)
        if (res.data.user.role === 1) {
          // Redirect to admin page
          navigate("/dashboard/admin");
        } else {
          // Redirect to home page
          navigate("/");
        }
      } else {
        notyf.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      notyf.error(error.response?.data?.message || "An error occurred during login.");
    }
  };
  return (
    <div className="auth-container d-flex justify-content-center">
      <div className="form-container2 ">
        <form onSubmit={handleSubmit}>
          <div className="logo">
          
          </div>
          <div className="logintitle">
            <h4 className="title">Samba Survey</h4>
          </div>
          <div className="mb-3">
            <label for="exampleInputemail" className="mb-2">User Name</label>

            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              id="exampleInputemail"
              placeholder="email"
              required
            />
          </div>
          <div className="mb-3">
            <label for="exampleInputPassword1" className="mb-2">Password</label>

            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
              id="exampleInputPassword1"
              placeholder="password"
              required
            />
          </div>
          <div className="mb-3 text-end ">
            <p>
              <span
                className="cursor-pointer"
                onClick={() => {
                  navigate("/forget-password");
                }}
              >
                Forget Password
              </span>
            </p>
          </div>
          
          <div className="text-center ">
            <button type="submit" className="btn btn-primary">
              Sign In
            </button>
          </div>

        </form>

      </div>
    </div>
  );
};

export default Login;
