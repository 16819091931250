import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import axios from "axios";
import { useParams } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import {useNavigate} from 'react-router-dom'


const VillageVoterList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { slug } = useParams();

  useEffect(() => {
    const fetchVillageVoterDetails = async () => {
      try {
        if (!slug) {
          console.error("Slug parameter is undefined");
          return;
        }

        let offlineData = [];

        // Check if offline and retrieve data from IndexedDB
        if (!navigator.onLine) {
          console.log("Offline mode. Fetching data from IndexedDB...");
          offlineData = await getDataFromIndexedDB();
        }

        if (offlineData.length > 0) {
          setData(offlineData);
          setLoading(false);
        } else {
          const response = await axios.get(
            `/api/v1/product/village/${encodeURIComponent(slug)}`
          );

          console.log("Response data:", response.data); // Add this line for debugging

          if (!Array.isArray(response.data)) {
            console.error("Response data is not an array");
            return;
          }

          // Update IndexedDB with new data
          // await updateIndexedDB(response.data);

          setData(response.data);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching voter details:", error);
        setLoading(false);
      }
    };

    fetchVillageVoterDetails();
  }, [slug]);

  const getDataFromIndexedDB = async () => {
    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open("voters-db", 1);

      request.onerror = function (event) {
        console.error("IndexedDB error:", event.target.error);
        reject(event.target.error);
      };

      request.onsuccess = function (event) {
        const db = event.target.result;
        const transaction = db.transaction(["votes"], "readonly");
        const objectStore = transaction.objectStore("votes");

        const data = [];

        objectStore.openCursor().onsuccess = function (event) {
          const cursor = event.target.result;
          if (cursor) {
            // Assuming the structure of your data is similar to what you provided
            const voterData = {
              FM_NAME_EN: cursor.value.FM_NAME_EN,
              C_HOUSE_NO: cursor.value.C_HOUSE_NO,
              EPIC_NO: cursor.value.EPIC_NO,
            };

            // Check if the voter's PART_NO matches the desired value
            if (cursor.value.PART_NO === "1") {
              // Change "1" to the desired PART_NO
              data.push(voterData);
            }
            cursor.continue();
          } else {
            resolve(data);
          }
        };
      };
    });
  };

  // const updateIndexedDB = async (newData) => {
  //   // Your logic to update IndexedDB with new data
  // };

  return (
    <Layout title={`${encodeURIComponent(slug)} voters`}>
      <div className="container mt-3">
        <div className="row">
          {loading ? (
            <div className="col-md-12 text-center">
              <h2 className="text-light">Loading...</h2>
            </div>
          ) : error ? (
            <div className="col-md-12 text-center">
              <h2>{error}</h2>
            </div>
          ) : (
            <>
            <div className="col-md-12">
              <h6 className="text-light">Total number of Voters: {data ? data.length : 0}</h6>
            </div>
              <div className="listCards" style={{width:'100%', display:"flex", flexWrap:'wrap',columnGap:'10px'}}>
              {data.map((item, index) => { 
                      var name = item.FM_NAME_EN;
                      name = name.replace(' ','-');
                    return (<div className="card card_container" key={index} onClick={() => navigate(`/voter/${encodeURIComponent(item.EPIC_NO)}`)}>
                        <div className="userAvatar" style={{width:'30%', display:'flex',justifyContent:'center'}}>
                          <Avatar alt={item.FM_NAME_EN} src="/static/images/avatar/1.jpg"sx={{ width: 70, height: 70 }} />
                        </div>
                        <div className="userdetails">
                          <h6 style={{margin:'0px'}}> {item.FM_NAME_EN} </h6>
                          <span>Sex : {item.GENDER}</span>
                          <span> Phone : {(item.MOBILE_NO != "") ? item.MOBILE_NO : "********"} </span>
                          <span>EPIC No : {item.EPIC_NO}</span>
                          <span> Age: {item.AGE}</span>

                        </div>
                        <div className="card-footer" style={{width:'100%'}}>
                          {item.voted ? (
                            <p className="d-flex-j">
                              {item.C_HOUSE_NO}
                              <input type="checkbox" checked={true} readOnly />
                            </p>
                          ) : (
                            <p className="d-flex-j">
                              {item.C_HOUSE_NO}
                              {item.fieldExists ? (
                                <input type="checkbox" checked={false} readOnly />
                              ) : (
                                <input type="checkbox" checked={false} disabled />
                              )}
                            </p>
                          )}
                        </div>
                    </div>
                  )})}
                {/* {data.map((item, index) => (
                  <div className="card" key={index}>
                    <Link
                      to={`/voter/${encodeURIComponent(item.FM_NAME_EN)}`}
                    >
                      <div className="card-header">

                        {item.FM_NAME_EN}
                      </div>
                      <div className="card-body">
                        <div>
                          <p>Sex: {item.GENDER} || {(item.MOBILE_NO != "") ? item.MOBILE_NO : "********"} || {item.EPIC_NO} | Age: {item.AGE}</p>
                        </div>
                      </div>
                      <div className="card-footer">
                        {item.voted ? (
                          <p className="d-flex-j">
                            {item.C_HOUSE_NO}
                            <input type="checkbox" checked={true} readOnly />
                          </p>
                        ) : (
                          <p className="d-flex-j">
                            {item.C_HOUSE_NO}
                            {item.fieldExists ? (
                              <input type="checkbox" checked={false} readOnly />
                            ) : (
                              <input type="checkbox" checked={false} disabled />
                            )}
                          </p>
                        )}
                      </div>

                    </Link>

                  </div>
                ))} */}
              </div>
              </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default VillageVoterList;
