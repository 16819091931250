import React, { useState } from 'react'
import Layout from '../../components/layout/Layout'
import '../../style/Register.css';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// import Logo from '../../Assets/Logo.png'

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [answer, setAnswer] = useState('')
  const notyf = new Notyf();


  const navigate = useNavigate();


  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const res = await axios.post(`/api/v1/auth/forgot-password`,
        { email, newPassword, answer }
      );
      if (res.data.success) {
        notyf.success(res.data.message);

        navigate("/login");
      } else {
        notyf.error(res.data.message)
      }
    } catch (error) {
      console.log(error);
      notyf.error("something went wrong")
    }
  }
  return (
    <div className="auth-container d-flex justify-content-center">
    <div className="form-container2">
      <form onSubmit={handleSubmit}>
        <div className="logo">
          {/* <img src={Logo} alt="Congress" /> */}
        </div>
        <div className="logintitle">
          <h4 className="title">Forget-Password</h4>
        </div>

        <div className="mb-3">

          <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" id="exampleInputemail" placeholder='email' required />

        </div>
        <div className="mb-3">

          <input type="text" value={answer} onChange={(e) => setAnswer(e.target.value)} className="form-control" id="exampleInputanswer" placeholder='Enter Your  Answer' required />

        </div>
        <div className="mb-3">
          <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} className="form-control" id="exampleInputPassword1" placeholder='password' required />
        </div>

        <div className="text-center ">
          <button type="submit" className="btn btn-primary">Reset</button>
        </div>
      </form>

    </div>
    </div>

  )
}

export default ForgotPassword;

