import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import Layout from "../components/layout/Layout";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import { useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import { saveAs } from 'file-saver';
import { useAuth } from "../context/auth";

// import { openDB } from "idb";


const AddVoter = () => {
const [FM_NAME_EN,setFM_NAME_EN]=useState("");
const [LASTNAME_EN,setLASTNAME_EN]=useState("");
const [PART_NO,setPART_NO]=useState("");
const [village,setVillage]=useState("");
const [AGE,setAge]=useState("");
const [GENDER,setGender]=useState("");
const [EPIC_NO,setEPIC_NO]=useState("");
const [SECTION_NO,setSECTION_NO]=useState("");
const [SLNOINPART,setSLNOINPART]=useState("");
const [C_HOUSE_NO, setC_HOUSE_NO]=useState("");
const [RLN_TYPE,setRLN_TYPE]=useState("");
const [RLN_FM_NM_EN,setRLN_FM_NM_EN]=useState("");
const [RLN_L_NM_EN,setRLN_L_NM_EN]=useState("");
  const [dob, setDob] = useState(null);
  const [mobileNo, setMobileNo] = useState("");
  const [voted, setVoted] = useState(false);
  const [died, setDied] = useState(false);
  const [submittedDob, setSubmittedDob] = useState(null);
  const [submittedMobileNo, setSubmittedMobileNo] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [surveyOption, setSurveyOption] = useState("");
  const navigate = useNavigate();
  const { slug } = useParams();
  const auth = JSON.parse(localStorage.getItem("auth"));
  const userRole = auth?.user?.role;
  const notyf = new Notyf();
  const [voter, setVoter] = useState({});
  const [audioData, setAudioData] = useState(null);
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [isRecording, setIsRecording] = useState(false);
  const [recording, setRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [askQuestions, setAskQuestions] = useState([]);
  const [selectedAskOptions, setSelectedAskOptions] = useState({});
  const [filters, setFilters] = useState({
    electionType: auth.user.electionType,
    state: auth.user.state,
    district: auth.user.district,
    assembly: auth.user.assembly,
    constituency: auth.user.constituency
  });

  // Check network status
  const isOnline = navigator.onLine;


  useEffect(() => {
    startRecording();
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (error) => {
            console.error("Error getting location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    getLocation();
  }, []);

  // Start recording audio
  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);

    mediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        audioChunksRef.current.push(event.data);
      }
    };

    mediaRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
      setAudioUrl(URL.createObjectURL(audioBlob));
      audioChunksRef.current = [];
    };

    mediaRecorderRef.current.start();
  };

  // // Stop recording audio
  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
  };

  // Convert blob to base64
  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };
  useEffect(() => {
    // Set the default value of surveyOption when the component mounts
    if (surveyOption) {
      setSurveyOption(surveyOption);
    }
  }, []);

  useEffect(() => {
    // Listen for changes in IndexedDB
    const listenForChanges = async () => {
      const db = await openDB("voters-db", 1);

      db.addEventListener("change", () => {
        // Handle changes
        console.log("Database changed");
      });

      return () => {
        // Remove event listener
        db.removeEventListener("change", () => { });
      };
    };

    listenForChanges();
  }, []);

  const handleDateChange = (date) => {
    if (date && typeof date.toDate === 'function') {
      const newDate = date.toDate();
      setDob(newDate);
      updateLocalData(slug, {  DOB: isNaN(newDate.getTime()) ? null : newDate.toISOString() });
    } else {
      setDob(null);
      updateLocalData(slug, { DOB: null });
    }
  };
  const handleMobileChange = (e) => {
    const inputMobileNo = e.target.value;
    const mobileNumber = inputMobileNo.replace(/\D/g, ""); // Remove non-digit characters
    if (mobileNumber.length <= 10) {
      setMobileNo(mobileNumber);
      updateLocalData(slug, {  MOBILE_NO: mobileNumber });
    }
  };
  const handleVotedChange = () => {
    const updatedVoted = !voted;
    setVoted(updatedVoted);
    updateLocalData(slug, {  voted: updatedVoted });
  };

  const handleDiedChange = () => {
    const updatedDied = !died;
    setDied(updatedDied);
    updateLocalData(slug, {  died: updatedDied });
  };

  const handleSurveyOptionChange = (option) => {
    setSurveyOption(option);
    updateLocalData(slug, {  surveyOption: option });
  };

 
  useEffect(() => {
    const fetchAskQuestions = async () => {
      try {
        const { electionType, state, district, assembly, constituency } = filters;
        const response = await axios.get('/api/v1/product/getquestions', {
          params: { electionType, state, district, assembly, constituency }
        });
  
        console.log('API Response:', response.data); // Log the API response
        setAskQuestions(response.data); // Set askQuestions with the fetched data
      } catch (error) {
        console.error('Error fetching survey questions:', error);
        setAskQuestions([]); // Ensure askQuestions is an array even if there's an error
      }
    };
    fetchAskQuestions();
  }, [filters]);
  

  const handleAskQuestionOptionChange = (questionId, selectedOption) => {
    setSelectedAskOptions((prevSelectedAskOptions) => ({
      ...prevSelectedAskOptions,
      [questionId]: selectedOption,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Stop recording before submission
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === "recording") {
      stopRecording();
    }
  
    let audioBase64 = null;
    if (audioUrl) {
      try {
        const audioBlob = await fetch(audioUrl).then(res => res.blob());
        audioBase64 = await blobToBase64(audioBlob);
      } catch (error) {
        console.error("Error converting audio to base64:", error);
      }
    }
  
    const user = JSON.parse(localStorage.getItem('auth'))?.user;
    if (!user || !user.name) {
      notyf.error("User is not authenticated.");
      return;
    }
  
    // Format selected options as "Question: SelectedOption"
    const formattedAskQuestions = Object.entries(selectedAskOptions).reduce((acc, [questionId, selectedOption]) => {
      const questionText = askQuestions.find(q => q._id === questionId)?.question;
      if (questionText) {
        acc[questionText] = selectedOption;
      }
      return acc;
    }, {});
  
    const updatedVoterDetails = {
      FM_NAME_EN,
      PART_NO,
      SECTION_NO,
      C_HOUSE_NO,
      LASTNAME_EN,
      RLN_TYPE,
      RLN_FM_NM_EN,
      RLN_L_NM_EN,
      EPIC_NO,
      GENDER,
      AGE,
      MOBILE_NO: mobileNo,
      DOB: dob ? (isNaN(dob.getTime()) ? null : dob.toISOString()) : null,
      voted,
      died,
      surveyOption,
      uploadedByName: user.name,
      location,
      askQuestions: formattedAskQuestions,
      audioData: audioBase64,
      uploadedBy: user._id,
      Type: auth.user.electionType,
      state: auth.user.state,
      district: auth.user.district,
      assembly: auth.user.assembly,
      constituency: auth.user.constituency
    };
  
    try {
      if (navigator.onLine) {
        
        const response = await axios.put(`/api/v1/product/allvotes/stage/${encodeURIComponent(slug==undefined?EPIC_NO:slug)}`, updatedVoterDetails, {
          headers: {
            "Content-Type": "application/json",
          },
        });
  
        if (response.status === 200) {
          notyf.success("Updated Successfully");
          navigate("/");
        } else {
          throw new Error('Server responded with an error');
        }
      } else {
        await updateLocalData(slug, updatedVoterDetails);
        notyf.success("Update saved locally");
        navigate("/");
      }
    } catch (error) {
      console.error("Error updating details:", error);
      notyf.error("Failed to update voter details");
    }
  };
  

  const openDB = () => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open("voters-db", 1);

      request.onerror = (event) => {
        reject("Error opening database");
      };

      request.onsuccess = (event) => {
        const db = event.target.result;
        resolve(db);
      };

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains("votes")) {
          db.createObjectStore("votes", { keyPath: "_id" }); // Ensure the key path matches your object structure
        }
      };
    });
  };

  const updateLocalData = async (slug=EPIC_NO, newData) => {
  
    console.log("Slug:", slug);
    try {
      const db = await openDB("voters-db", 1);
      const tx = db.transaction("votes", "readwrite");
      const store = tx.objectStore("votes");

      // Get existing data
      const request = store.get(slug);

      request.onsuccess = async (event) => {
        const existingData = event.target.result;

        if (existingData) {
          // Clone the existing data to avoid mutating it directly
          const updatedData = { ...existingData };

          // Update specified fields in the existing data
          Object.keys(newData).forEach((key) => {
            if (newData[key] !== undefined) {
              updatedData[key] = newData[key];
            }
          });

          // Store the updated data back into IndexedDB
          const putRequest = store.put(updatedData); // Removed the slug parameter

          putRequest.onsuccess = async () => {
            console.log("Updated local data for slug:", slug);

            // Re-fetch the updated data from IndexedDB
            const updatedRequest = store.get(slug);
            updatedRequest.onsuccess = async (event) => {
              const updatedResult = event.target.result;
              console.log("Refreshed local data:", updatedResult);

              // Here you can update the state with the refreshed data if needed
            };

            updatedRequest.onerror = (error) => {
              console.error("Error refreshing local data:", error);
            };
          };

          putRequest.onerror = (error) => {
            console.error("Error updating local data:", error);
            setError(error.message);
          };
        } else {
          console.log("No existing data found for slug:", slug);
        }
      };

      request.onerror = (error) => {
        console.error("Error fetching existing data:", error);
        setError(error.message);
      };

      await tx.done;
      console.log("Transaction completed successfully.");
    } catch (error) {
      console.error("Error updating local data:", error);
      setError(error.message);
    }
  };

  const validDate = (current) => {
    const eighteenYearsAgo = new Date("2024-01-01"); // 18 years ago from 01-01-2024
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
    return current.isBefore(eighteenYearsAgo);
  };

 

console.log(askQuestions.length);
  return (
    <Layout title={`Voter: New Voter`}>
      <div className="container voterpage mt-4">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <form onSubmit={handleSubmit} className="voter_details_container">
                {/* <div className="avatar_container">
                  <Avatar alt={FM_NAME_EN} src="/static/images/avatar/1.jpg" />
                </div> */}
                <div className="details_container">
                  {error && <p>Error: {error}</p>}
                  <div className="form-group">
                    <label>First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={FM_NAME_EN}
                      onChange={(e) => setFM_NAME_EN(e.target.value)}
                      placeholder="First Name"
                    />
                  </div>
                  <div className="form-group">
                    <label>Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={LASTNAME_EN}
                      onChange={(e) => setLASTNAME_EN(e.target.value)}
                      placeholder="Last Name"
                    />
                  </div>
                  <div className="form-group">
                    <label>Part No.</label>
                    <input
                      type="number"
                      className="form-control"
                      value={PART_NO}
                      onChange={(e) => setPART_NO(e.target.value)}
                      placeholder="Part No."
                    />
                  </div>
                  <div className="form-group">
                    <label>Section No.</label>
                    <input
                      type="number"
                      className="form-control"
                      value={SECTION_NO}
                      onChange={(e) => setSECTION_NO(e.target.value)}
                      placeholder="section no."
                    />
                  </div>
                 
                  <div className="form-group">
                    <label>Village</label>
                    <input
                      type="text"
                      className="form-control"
                      value={village}
                      onChange={(e) => setVillage(e.target.value)}
                      placeholder="Village Name"
                    />
                  </div>
                  <div className="form-group">
                    <label>Relation Type</label>
                    <input
                      type="text"
                      className="form-control"
                      value={RLN_TYPE}
                      onChange={(e) => setRLN_TYPE(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label>Family Head</label>
                    <input
                      type="text"
                      className="form-control"
                      value={RLN_FM_NM_EN}
                      onChange={(e) => setRLN_FM_NM_EN(e.target.value)}
                      placeholder="First Name"
                    />
                  </div>
                  <div className="form-group">
                    <label>Family Head</label>
                    <input
                      type="text"
                      className="form-control"
                      value={RLN_L_NM_EN}
                      onChange={(e) => setRLN_L_NM_EN(e.target.value)}
                      placeholder="Last Name"
                    />
                  </div>
                  <div className="form-group">
                    <label>House No.</label>
                    <input
                      type="text"
                      className="form-control"
                      value={C_HOUSE_NO}
                      onChange={(e) => setC_HOUSE_NO(e.target.value)}
                      placeholder="Home address"
                    />
                  </div>
                  <div className="form-group">
                    <label>Age</label>
                    <input
                      type="number"
                      className="form-control"
                      value={AGE}
                      onChange={(e) => setAge(e.target.value)}
                      placeholder="Age"
                    />
                  </div>
                  <div className="form-group">
                    <label>Gender</label>
                    <select
                      className="form-control"
                      value={GENDER}
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Voter Id No.</label>
                    <input
                      type="text"
                      className="form-control"
                      value={EPIC_NO}
                      onChange={(e) => setEPIC_NO(e.target.value)}
                      placeholder="V Id or EPIC No."
                    />
                  </div>
              </div>
              <div className="form_container mt-3">
                <div className="form-group">
                  <label>D.O.B</label>
                  <Datetime
                    inputProps={{
                      id: "dob",
                      className: "form-control",
                      placeholder: 'YYYY-MM-D '
                    }}

                    value={dob || undefined} // use undefined to ensure react-datetime uses its default value when dob is null
                    onChange={handleDateChange}
                    dateFormat="YYYY-MM-DD"
                    timeFormat={false}
                    isValidDate={validDate}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="mobileNo">Mobile No.</label>
                  <input
                    type="number"
                    id="mobileNo"
                    className="form-control"
                    value={mobileNo}
                    onChange={handleMobileChange}
                    placeholder="Phone"
                    readOnly={userRole === 0}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="surveyOption">Survey Option</label>
                  <select
                    id="surveyOption"
                    className="form-control"
                    value={surveyOption}
                    onChange={(e) => handleSurveyOptionChange(e.target.value)}
                  >
                    <option value="">Select an option</option>
                    <option value="Ours">Ours</option>
                    <option value="Opposite">Against</option>
                    <option value="Doubt">Doubt</option>
                    <option value="Others">Others</option>
                  </select>
                </div>
                <div className="form-group">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      id="voted"
                      className="form-check-input"
                      checked={voted}
                      onChange={handleVotedChange}
                      disabled={userRole === 0}
                    />
                    <label htmlFor="voted" className="form-check-label">
                      Voted
                    </label>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      id="died"
                      className="form-check-input"
                      checked={died}
                      onChange={handleDiedChange}
                      disabled={userRole === 0}
                    />
                    <label htmlFor="died" className="form-check-label">
                      Died
                    </label>
                  </div>
                </div>
                <div>
                {Array.isArray(askQuestions) && askQuestions.length > 0 ? (
                askQuestions.map((question) => (
                  <div className="form-group" key={question._id}>
                    <label htmlFor={question._id}>{question.question}</label>
                    <select
                      id={question._id}
                      className="form-control"
                      value={selectedAskOptions[question._id] || ""}
                      onChange={(e) => handleAskQuestionOptionChange(question._id, e.target.value)}
                      name={question.question}
                    >
                      <option value="">Select an option</option>
                      {question.options.map((option) => (
                        <option key={option._id} value={option.text}>{option.text}</option>
                      ))}
                    </select>
                  </div>
                ))
              ) : (
                <p>Loading survey questions...</p>
              )
}
                </div>



                <button type="button" onClick={stopRecording} className="btn btn-outline-success mb-3 w-100" >
                  survey Completed
                </button>
                {userRole !== 0 && (
                  <button type="submit" className="btn btn-primary">
                    Update Details
                  </button>
                )}
                {submittedDob && submittedDob instanceof Date && (
                  <p>
                    Updated Date of Birth: {submittedDob.toDateString()}
                  </p>
                )}
                {submittedMobileNo && (
                  <p>Updated Mobile No.: {submittedMobileNo}</p>
                )}

              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddVoter;

