import React, { useState, useEffect, useRef } from "react";
import Layout from "../components/layout/Layout";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { openDB } from "idb";
import { IconButton } from "@mui/material";
import FamilyRestroomOutlined from "@mui/icons-material/FamilyRestroomOutlined";

const FamilyList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalFamily, setTotalFamily] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [moreDataAvailable, setMoreDataAvailable] = useState(true);
  const pageSize = 100;
  const containerRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        containerRef.current &&
        containerRef.current.scrollTop + containerRef.current.clientHeight >=
        containerRef.current.scrollHeight &&
        !fetching &&
        moreDataAvailable
      ) {
        setFetching(true);
      }
    };

    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [fetching, moreDataAvailable]);

  useEffect(() => {
    const fetchDataOnScroll = async () => {
      if (fetching) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };

    fetchDataOnScroll();
  }, [fetching]);

  const authData = localStorage.getItem("auth");
  const authObject = JSON.parse(authData);
  const user = authObject.user;
  const [start, end] = user.partNoRanges;
  const fetchData = async () => {
    setLoading(true);
    try {
      let fetchedData;
      let totalFamilyCount;
      if (navigator.onLine) {
        const response = await axios.get(`/api/v1/product/family`, {
          params: {
            electionType: user.electionType,
            state: user.state,
            district: user.district,
            assemblyName: user.assembly,
            constituency: user.constituency,
            start: start,
            end: end,
          },
        });
        fetchedData = response.data.data;
        totalFamilyCount = response.data.rlnCount;

        if (fetchedData.length < pageSize) {
          setMoreDataAvailable(false);
        }
      } else {
        const { data: fetchedDataFromIndexedDB, totalFamily: totalFamilyFromIndexedDB, moreData } = await fetchDataFromIndexedDB(
          currentPage,
          pageSize
        );
        fetchedData = fetchedDataFromIndexedDB;
        totalFamilyCount = totalFamilyFromIndexedDB;
        setMoreDataAvailable(moreData);
      }

      if (currentPage === 1) {
        setData(fetchedData);
      } else {
        setData((prevData) => [...prevData, ...fetchedData]);
      }
      setTotalFamily(totalFamilyCount);
      setLoading(false);
      setFetching(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("An error occurred while fetching data.");
      setLoading(false);
      setFetching(false);
    }
  };

  const fetchDataFromIndexedDB = async (page, pageSize) => {
    try {
      const db = await openDB("voters-db", 1);
      if (!db.objectStoreNames.contains("votes")) {
        throw new Error("Object store 'votes' not found in the database.");
      }
      const tx = db.transaction("votes", "readonly");
      const store = tx.objectStore("votes");

      const offset = (page - 1) * pageSize;
      const limit = pageSize;
      const data = await store.getAll(undefined, limit, offset);

      const aggregatedData = aggregateFamilyCounts(data);

      const totalFamilyCount = aggregatedData.reduce((acc, item) => acc + item.count, 0);

      const totalCountRequest = store.count();
      const totalCount = await totalCountRequest;
      const moreData = totalCount > offset + limit;

      return { data: aggregatedData, totalFamily: totalFamilyCount, moreData };
    } catch (error) {
      console.error("Error fetching data from IndexedDB:", error);
      throw error;
    }
  };

  const aggregateFamilyCounts = (data) => {
    const aggregatedData = {};
    data.forEach((item) => {
      const { RLN_FM_NM_EN } = item;
      if (!aggregatedData[RLN_FM_NM_EN]) {
        aggregatedData[RLN_FM_NM_EN] = 1;
      } else {
        aggregatedData[RLN_FM_NM_EN] += 1;
      }
    });

    return Object.keys(aggregatedData).map((RLN_FM_NM_EN) => ({
      RLN_FM_NM_EN,
      count: aggregatedData[RLN_FM_NM_EN],
    }));
  };

  const calculateSerialNumber = (index) => {
    return index + 1;
  };

  return (
    <Layout title={"All Villages"}>
      <div className="container mt-3" ref={containerRef}>
        <div className="row">
          <div className="col-md-12 familycard">
            {loading && <h2 className="text-center">Loading...</h2>}
            {error && <h2 className="text-center">{error}</h2>}
          </div>
          {data.length > 0 && (
            <>
              <h6 className="text-light mb-3">Total number of Families: {totalFamily}</h6>
              {data.map((item, index) => {
                const name = item.RLN_FM_NM_EN.replace(' ', '-');
                return (
                  <div
                    className="col-sm-4 mb-3"
                    onClick={() => navigate(`/family/${encodeURIComponent(item.RLN_FM_NM_EN)}`)}
                    key={index}
                  >
                    <div className="card">
                      <div className="card-body text-center">
                        <IconButton>
                          <FamilyRestroomOutlined style={{ fontSize: '40px' }} />
                        </IconButton>
                        <h4>{item.RLN_FM_NM_EN} </h4>
                      </div>
                      <div className="card-footer text-center">
                        <h6>Total: {item.count}</h6>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
          {data.length === 0 && !loading && <h2 className="text-center">No data found</h2>}
        </div>
      </div>
      {fetching && <h2 className="text-center">Loading more...</h2>}
    </Layout>
  );
};

export default FamilyList;
