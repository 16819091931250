import React, { useState, useEffect } from 'react';
import Layout from '../components/layout/Layout';
import axios from 'axios';
import { useParams } from 'react-router-dom';

export default function Confirm() {
    const { verificationToken } = useParams();
    const [verified, setVerified] = useState(false);

    useEffect(() => {
        const confirmUser = async () => {
            try {
                const response = await axios.get(`/api/v1/auth/confirm/${verificationToken}`);
                if (response.status === 200) {
                    setVerified(true);

                    // Update verified status to true
                    // If verification is successful, send another request to update verified status in the database
                    await axios.post(`/api/v1/auth/updateVerified/${verificationToken}`);
                }
            } catch (error) {
                console.error(error);
                // Handle error
            }
        };

        confirmUser();
    }, [verificationToken]);

    return (
        <Layout title={'User Verification'}>
            {verified ? (
                <h1>Email Verified Successfully!</h1>
            ) : (
                <h1>Verifying Email...</h1>
            )}
            {/* You can show a loading indicator here if needed */}
        </Layout>
    );
}
