import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import Layout from "../components/layout/Layout";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import { useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import { saveAs } from 'file-saver';
import { useAuth } from "../context/auth";

// import { openDB } from "idb";


const VoterDetails = () => {
  const [voterDetails, setVoterDetails] = useState({});
  const [dob, setDob] = useState(null);
  const [mobileNo, setMobileNo] = useState("");
  const [voted, setVoted] = useState(false);
  const [died, setDied] = useState(false);
  const [submittedDob, setSubmittedDob] = useState(null);
  const [submittedMobileNo, setSubmittedMobileNo] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [surveyOption, setSurveyOption] = useState("");
  const navigate = useNavigate();
  const { slug } = useParams();
  const auth = JSON.parse(localStorage.getItem("auth"));
  const userRole = auth?.user?.role;
  const notyf = new Notyf();
  const [voter, setVoter] = useState({});
  const [audioData, setAudioData] = useState(null);
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [isRecording, setIsRecording] = useState(false);
  const [recording, setRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [askQuestions, setAskQuestions] = useState([]);
  const [selectedAskOptions, setSelectedAskOptions] = useState({});


  const [filters, setFilters] = useState({
    electionType: auth.user.electionType,
    state: auth.user.state,
    district: auth.user.district,
    assembly: auth.user.assembly,
    constituency: auth.user.constituency
  });

  // Check network status
  const isOnline = navigator.onLine;


  useEffect(() => {
    startRecording();
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            setLocation({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (error) => {
            console.error("Error getting location:", error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    getLocation();
  }, []);

  // Start recording audio
  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);

    mediaRecorderRef.current.ondataavailable = (event) => {
      if (event.data.size > 0) {
        audioChunksRef.current.push(event.data);
      }
    };

    mediaRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
      setAudioUrl(URL.createObjectURL(audioBlob));
      audioChunksRef.current = [];
    };

    mediaRecorderRef.current.start();
  };

  // // Stop recording audio
  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
  };

  // Convert blob to base64
  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };
  useEffect(() => {
    const fetchVoterDetails = async () => {
      try {
        if (!slug) {
          console.error("Slug parameter is undefined");
          return;
        }

        console.log("Online status:", isOnline);

        if (!isOnline) {
          // Attempt to fetch voter details from IndexedDB
          let localData = await getLocalData(slug);
          console.log("Local data:", localData);

          if (!localData || Object.keys(localData).length === 0) {
            console.log("Local data not found or user offline");
          } else {
            console.log("Local data found for slug:", slug);
            // Update state with local data
            setVoterDetails(localData); // <-- Update voterDetails state
            setDob(new Date(localData.DOB)); // Parse the date string
            setMobileNo(localData.MOBILE_NO || "");
            setVoted(localData.voted || false);
            setDied(localData.died || false);
            setLoading(false);
            return;
          }
        }

        // User is online, fetch voter details from the API
        const response = await axios.get(`/api/v1/product/allvotes/${encodeURIComponent(slug)}`);
        setVoterDetails(response.data);
        setDob(new Date(response.data.DOB)); // Parse the date string
        setMobileNo(response.data.MOBILE_NO || "");
        setVoted(response.data.voted || false);
        setDied(response.data.died || false);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching voter details:", error);
        setError(error.message);
      }
    };


    fetchVoterDetails();
  }, [slug, isOnline]);

  useEffect(() => {
    // Set the default value of surveyOption when the component mounts
    if (voterDetails && voterDetails.surveyOption) {
      setSurveyOption(voterDetails.surveyOption);
    }
  }, [voterDetails]);

  useEffect(() => {
    // Listen for changes in IndexedDB
    const listenForChanges = async () => {
      const db = await openDB("voters-db", 1);

      db.addEventListener("change", () => {
        // Handle changes
        console.log("Database changed");
      });

      return () => {
        // Remove event listener
        db.removeEventListener("change", () => { });
      };
    };

    listenForChanges();
  }, []);

  const handleDateChange = (date) => {
    if (date && typeof date.toDate === 'function') {
      const newDate = date.toDate();
      setDob(newDate);
      updateLocalData(slug, { ...voterDetails, DOB: isNaN(newDate.getTime()) ? null : newDate.toISOString() });
    } else {
      setDob(null);
      updateLocalData(slug, { ...voterDetails, DOB: null });
    }
  };
  const handleMobileChange = (e) => {
    const inputMobileNo = e.target.value;
    const mobileNumber = inputMobileNo.replace(/\D/g, ""); // Remove non-digit characters
    if (mobileNumber.length <= 10) {
      setMobileNo(mobileNumber);
      updateLocalData(slug, { ...voterDetails, MOBILE_NO: mobileNumber });
    }
  };
  const handleVotedChange = () => {
    const updatedVoted = !voted;
    setVoted(updatedVoted);
    updateLocalData(slug, { ...voterDetails, voted: updatedVoted });
  };

  const handleDiedChange = () => {
    const updatedDied = !died;
    setDied(updatedDied);
    updateLocalData(slug, { ...voterDetails, died: updatedDied });
  };

  const handleSurveyOptionChange = (option) => {
    setSurveyOption(option);
    updateLocalData(slug, { ...voterDetails, surveyOption: option });
  };

  const getUserFromLocalStorage = () => {
    const userData = localStorage.getItem('auth');
    return userData ? JSON.parse(userData).user : null;
  }

  const fetchBlob = async (url) => {
    const response = await fetch(url);
    return response.blob();
  };
  useEffect(() => {
    const fetchAskQuestions = async () => {
      try {
        const { electionType, state, district, assembly, constituency } = filters;
        const response = await axios.get('/api/v1/product/getquestions', {
          params: { electionType, state, district, assembly, constituency }
        });
  
        console.log('API Response:', response.data); // Log the API response
        setAskQuestions(response.data); // Set askQuestions with the fetched data
      } catch (error) {
        console.error('Error fetching survey questions:', error);
        setAskQuestions([]); // Ensure askQuestions is an array even if there's an error
      }
    };
    fetchAskQuestions();
  }, [filters]);
  

  const handleAskQuestionOptionChange = (questionId, selectedOption) => {
    setSelectedAskOptions((prevSelectedAskOptions) => ({
      ...prevSelectedAskOptions,
      [questionId]: selectedOption,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Stop recording before submission
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === "recording") {
      stopRecording();
    }
  
    let audioBase64 = null;
    if (audioUrl) {
      try {
        const audioBlob = await fetch(audioUrl).then(res => res.blob());
        audioBase64 = await blobToBase64(audioBlob);
      } catch (error) {
        console.error("Error converting audio to base64:", error);
      }
    }
  
    const user = JSON.parse(localStorage.getItem('auth'))?.user;
    if (!user || !user.name) {
      notyf.error("User is not authenticated.");
      return;
    }
  
    // Format selected options as "Question: SelectedOption"
    const formattedAskQuestions = Object.entries(selectedAskOptions).reduce((acc, [questionId, selectedOption]) => {
      const questionText = askQuestions.find(q => q._id === questionId)?.question;
      if (questionText) {
        acc[questionText] = selectedOption;
      }
      return acc;
    }, {});
  
    const updatedVoterDetails = {
      ...voterDetails,
      DOB: dob ? (isNaN(dob.getTime()) ? null : dob.toISOString()) : null,
      MOBILE_NO: mobileNo,
      voted,
      died,
      surveyOption,
      uploadedBy: user._id,
      uploadedByName: user.name,
      location,
      audioData: audioBase64,
      askQuestions: formattedAskQuestions,
    };
  
    try {
      if (navigator.onLine) {
        const response = await axios.put(`/api/v1/product/allvotes/stage/${encodeURIComponent(slug)}`, updatedVoterDetails, {
          headers: {
            "Content-Type": "application/json",
          },
        });
  
        if (response.status === 200) {
          notyf.success("Updated Successfully");
          navigate("/");
        } else {
          throw new Error('Server responded with an error');
        }
      } else {
        await updateLocalData(slug, updatedVoterDetails);
        notyf.success("Update saved locally");
        navigate("/");
      }
    } catch (error) {
      console.error("Error updating details:", error);
      notyf.error("Failed to update voter details");
    }
  };
  


  const getLocalData = async (slug) => {
    console.log("Getting local data for slug:", slug);
    try {
      const db = await openDB("voters-db", 1);
      const tx = db.transaction("votes", "readonly");
      const store = tx.objectStore("votes");
      const cursor = await store.openCursor();

      return new Promise((resolve, reject) => {
        cursor.onsuccess = (event) => {
          const cursor = event.target.result;
          if (cursor) {
            const data = cursor.value;
            if (data.FM_NAME_EN === slug) {
              console.log("Retrieved data:", data);
              resolve(data);
            } else {
              cursor.continue();
            }
          } else {
            console.log("Data not found for slug:", slug);
            resolve(null);
          }
        };
        cursor.onerror = () => {
          console.error("Error getting local data:", cursor.error);
          reject(cursor.error);
        };
      });
    } catch (error) {
      console.error("Error opening database:", error);
      setError(error.message);
      return null;
    }
  };


  const openDB = () => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open("voters-db", 1);

      request.onerror = (event) => {
        reject("Error opening database");
      };

      request.onsuccess = (event) => {
        const db = event.target.result;
        resolve(db);
      };

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains("votes")) {
          db.createObjectStore("votes", { keyPath: "_id" }); // Ensure the key path matches your object structure
        }
      };
    });
  };

  const updateLocalData = async (slug, newData) => {
    console.log("Slug:", slug);
    try {
      const db = await openDB("voters-db", 1);
      const tx = db.transaction("votes", "readwrite");
      const store = tx.objectStore("votes");

      // Get existing data
      const request = store.get(slug);

      request.onsuccess = async (event) => {
        const existingData = event.target.result;

        if (existingData) {
          // Clone the existing data to avoid mutating it directly
          const updatedData = { ...existingData };

          // Update specified fields in the existing data
          Object.keys(newData).forEach((key) => {
            if (newData[key] !== undefined) {
              updatedData[key] = newData[key];
            }
          });

          // Store the updated data back into IndexedDB
          const putRequest = store.put(updatedData); // Removed the slug parameter

          putRequest.onsuccess = async () => {
            console.log("Updated local data for slug:", slug);

            // Re-fetch the updated data from IndexedDB
            const updatedRequest = store.get(slug);
            updatedRequest.onsuccess = async (event) => {
              const updatedResult = event.target.result;
              console.log("Refreshed local data:", updatedResult);

              // Here you can update the state with the refreshed data if needed
            };

            updatedRequest.onerror = (error) => {
              console.error("Error refreshing local data:", error);
            };
          };

          putRequest.onerror = (error) => {
            console.error("Error updating local data:", error);
            setError(error.message);
          };
        } else {
          console.log("No existing data found for slug:", slug);
        }
      };

      request.onerror = (error) => {
        console.error("Error fetching existing data:", error);
        setError(error.message);
      };

      await tx.done;
      console.log("Transaction completed successfully.");
    } catch (error) {
      console.error("Error updating local data:", error);
      setError(error.message);
    }
  };

  const validDate = (current) => {
    const eighteenYearsAgo = new Date("2024-01-01"); // 18 years ago from 01-01-2024
    eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
    return current.isBefore(eighteenYearsAgo);
  };

 

console.log(askQuestions.length);
  return (
    <Layout title={`Voter: ${encodeURIComponent(slug)}`}>
      <div className="container voterpage mt-4">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <form onSubmit={handleSubmit} className="voter_details_container">
              <div className="form_container d-flex column-gap-5">
                <div className="avatar_container">
                  <Avatar alt={voterDetails.FM_NAME_EN} src="/static/images/avatar/1.jpg" />
                </div>
                <div className="details_container">
                  {error && <p>Error: {error}</p>}
                  <p>Name: <strong>{voterDetails.FM_NAME_EN}</strong></p>
                  <p>Last Name: <strong>{voterDetails.LASTNAME_EN}</strong></p>
                  <p>Part No.: <strong>{voterDetails.PART_NO}</strong></p>
                  <p>Village:<strong> {voterDetails.C_HOUSE_NO}</strong></p>
                  <p>Age/Gender: <strong>{voterDetails.AGE} {voterDetails.GENDER}</strong> </p>
                  <p>Voter Id No: <strong>{voterDetails.EPIC_NO}</strong></p>
                </div>
              </div>
              <div className="form_container mt-3">
                <div className="form-group">
                  <label>D.O.B</label>
                  <Datetime
                    inputProps={{
                      id: "dob",
                      className: "form-control",
                      placeholder: 'YYYY-MM-D '
                    }}

                    value={dob || undefined} // use undefined to ensure react-datetime uses its default value when dob is null
                    onChange={handleDateChange}
                    dateFormat="YYYY-MM-DD"
                    timeFormat={false}
                    isValidDate={validDate}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="mobileNo">Mobile No.</label>
                  <input
                    type="number"
                    id="mobileNo"
                    className="form-control"
                    value={mobileNo}
                    onChange={handleMobileChange}
                    placeholder="Phone"
                    readOnly={userRole === 0}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="surveyOption">Survey Option</label>
                  <select
                    id="surveyOption"
                    className="form-control"
                    value={surveyOption}
                    onChange={(e) => handleSurveyOptionChange(e.target.value)}
                  >
                    <option value="">Select an option</option>
                    <option value="Ours">Ours</option>
                    <option value="Opposite">Against</option>
                    <option value="Doubt">Doubt</option>
                    <option value="Others">Others</option>
                  </select>
                </div>
                <div className="form-group">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      id="voted"
                      className="form-check-input"
                      checked={voted}
                      onChange={handleVotedChange}
                      disabled={userRole === 0}
                    />
                    <label htmlFor="voted" className="form-check-label">
                      Voted
                    </label>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      id="died"
                      className="form-check-input"
                      checked={died}
                      onChange={handleDiedChange}
                      disabled={userRole === 0}
                    />
                    <label htmlFor="died" className="form-check-label">
                      Died
                    </label>
                  </div>
                </div>
                <div>
                {Array.isArray(askQuestions) && askQuestions.length > 0 ? (
                askQuestions.map((question) => (
                  <div className="form-group" key={question._id}>
                    <label htmlFor={question._id}>{question.question}</label>
                    <select
                      id={question._id}
                      className="form-control"
                      value={selectedAskOptions[question._id] || ""}
                      onChange={(e) => handleAskQuestionOptionChange(question._id, e.target.value)}
                      name={question.question}
                    >
                      <option value="">Select an option</option>
                      {question.options.map((option) => (
                        <option key={option._id} value={option.text}>{option.text}</option>
                      ))}
                    </select>
                  </div>
                ))
              ) : (
                <p>Loading survey questions...</p>
              )
}
                </div>



                <button type="button" onClick={stopRecording} className="btn btn-outline-success mb-3 w-100" >
                  survey Completed
                </button>

                {/* {audioUrl && (
                  <div>
                    <audio controls src={audioUrl} />

                    <p>{audioUrl}</p>
                  </div>
                )} */}
                {userRole !== 0 && (
                  <button type="submit" className="btn btn-primary">
                    Update Details
                  </button>
                )}
                {submittedDob && submittedDob instanceof Date && (
                  <p>
                    Updated Date of Birth: {submittedDob.toDateString()}
                  </p>
                )}
                {submittedMobileNo && (
                  <p>Updated Mobile No.: {submittedMobileNo}</p>
                )}

              </div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default VoterDetails;

