import React, { useState, useEffect } from 'react';
import '../../style/Register.css';
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import { useAuth } from "../../context/auth";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// import Logo from "../../Assets/JanAdharlogo.png";

const Register = () => {
  const [auth] = useAuth();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [electionType, setElectionType] = useState('');
  const [state, setState] = useState('');
  const [district, setDistrict] = useState('');
  const [assemblyName, setAssemblyName] = useState('');
  const [constituency, setConstituency] = useState('');
  const [role, setRole] = useState('');
  const [partNoRanges, setPartNoRanges] = useState([]);
  const [types, setTypes] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [assemblies, setAssemblies] = useState([]);
  const [constituencies, setConstituencies] = useState([]);
  const navigate = useNavigate();
  const notyf = new Notyf();

  useEffect(() => {
    const fetchTypes = async () => {
      try {
        const res = await axios.get('/api/v1/product/types', { headers: { 'Cache-Control': 'no-cache' } });
        setTypes(res.data.types || []);
      } catch (error) {
        console.error(error);
        notyf.error("Failed to fetch types");
      }
    };
    fetchTypes();
  }, []);

  useEffect(() => {
    if (electionType) {
      const fetchStates = async () => {
        try {
          const res = await axios.get(`/api/v1/product/states?type=${electionType}`, { headers: { 'Cache-Control': 'no-cache' } });
          setStates(res.data.states || []);
        } catch (error) {
          console.error(error);
          notyf.error("Failed to fetch states");
        }
      };
      fetchStates();
    }
  }, [electionType]);

  useEffect(() => {
    if (state && electionType) {
      const fetchConstituencies = async () => {
        try {
          const res = await axios.get(`/api/v1/product/constituencies?type=${electionType}&state=${state}`, { headers: { 'Cache-Control': 'no-cache' } });
          setConstituencies(res.data.constituencies || []);
        } catch (error) {
          console.error(error);
          notyf.error("Failed to fetch constituencies");
        }
      };
      fetchConstituencies();
    }
  }, [state, electionType]);

  useEffect(() => {
    if (electionType === "Vidhan Sabha" && state) {
      const fetchDistricts = async () => {
        try {
          const res = await axios.get(`/api/v1/product/districts?type=${electionType}&state=${state}`, { headers: { 'Cache-Control': 'no-cache' } });
          setDistricts(res.data.districts || []);
        } catch (error) {
          console.error(error);
          notyf.error("Failed to fetch districts");
        }
      };
      fetchDistricts();
    }
  }, [state, electionType]);

  useEffect(() => {
    if (electionType === 'Vidhan Sabha' && state && district) {
      const fetchAssemblies = async () => {
        try {
          const res = await axios.get(`/api/v1/product/assemblies?type=${electionType}&state=${state}&district=${district}`, { headers: { 'Cache-Control': 'no-cache' } });
          setAssemblies(res.data.assemblies || []);
        } catch (error) {
          console.error(error);
          notyf.error("Failed to fetch assemblies");
        }
      };
      fetchAssemblies();
    }
  }, [electionType, state, district]);

  useEffect(() => {
    if (electionType === 'Vidhan Sabha' && state && district && assemblyName) {
      const fetchConstituencies = async () => {
        try {
          const res = await axios.get(`/api/v1/product/constituencies?type=${electionType}&state=${state}&district=${district}&assembly=${assemblyName}`, { headers: { 'Cache-Control': 'no-cache' } });
          setConstituencies(res.data.constituencies || []);
        } catch (error) {
          console.error(error);
          notyf.error("Failed to fetch constituencies");
        }
      };
      fetchConstituencies();
    }
  }, [electionType, state, district, assemblyName]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const body = {
        name: name,
        email: email,
        password: password,
        phone: phone,
        electionType: auth.user.role === 2 ? auth.user.electionType : electionType,
        state: auth.user.role === 2 ? auth.user.state : state,
        district: auth.user.role === 2 ? auth.user.district : district,
        assemblyName: auth.user.role === 2 ? auth.user.assembly : assemblyName,
        constituency: auth.user.role === 2 ? auth.user.constituency : constituency,
        role: role,
        partNoRanges: partNoRanges

      }
      console.log(body);
      const res = await axios.post(`/api/v1/auth/register`, body, { headers: { 'Cache-Control': 'no-cache' } });
      if (res.data.success) {
        notyf.success(res.data.message);
        navigate('/');
      } else {
        notyf.error(res.data.message);
      }
    } catch (error) {
      console.error(error);
      notyf.error(error.response?.data?.message || "An error occurred during registration.");
    }
  };

  return (
    <div className="auth-container">
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <div className="mb-3 input_control">
            <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="form-control" placeholder='Name' required />
          </div>
          <div className="mb-3 input_control">
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder='Email' required />
          </div>
          <div className="mb-3 input_control">
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-control" placeholder='Password' required />
          </div>
          <div className="mb-3 input_control">
            <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} className="form-control" placeholder='Phone' required />
          </div>
          {auth?.user?.role === 2 ? null : <><div className="mb-3 input_control">
            <select value={electionType} onChange={(e) => setElectionType(e.target.value)} className="form-control" required>
              <option value="">Select Election Type</option>
              {types.map(type => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
          </div>
            <div className="mb-3 input_control">
              <select value={state} onChange={(e) => setState(e.target.value)} className="form-control" required>
                <option value="">Select State</option>
                {states.map(state => (
                  <option key={state} value={state}>{state}</option>
                ))}
              </select>
            </div>



            {electionType === "Vidhan Sabha" ? (
              <>
                <div className="mb-3 input_control">
                  <select value={district} onChange={(e) => setDistrict(e.target.value)} className="form-control" required>
                    <option value="">Select District</option>
                    {districts.map(district => (
                      <option key={district} value={district}>{district}</option>
                    ))}
                  </select>
                </div>

                <div className="mb-3 input_control">
                  <select value={assemblyName} onChange={(e) => setAssemblyName(e.target.value)} className="form-control" required>
                    <option value="">Select Assembly</option>
                    {assemblies.map(assembly => (
                      <option key={assembly} value={assembly}>{assembly}</option>
                    ))}
                  </select>
                </div>
              </>
            ) : (
              <div className="mb-3 input_control">
                <select value={constituency} onChange={(e) => setConstituency(e.target.value)} className="form-control" required>
                  <option value="">Select Constituency</option>
                  {constituencies.map(constituency => (
                    <option key={constituency} value={constituency}>{constituency}</option>
                  ))}
                </select>
              </div>
            )
            }
          </>}
          <div className="mb-3 input_control">
            <select value={role} onChange={(e) => setRole(e.target.value)} className="form-control" required>
              <option value="" disabled>Select Role</option>
              {/* <option value={'0'}>Default user</option> */}
              {/* {auth?.user?.role === 1 ? <option value={'1'}>Admin </option>:null} */}
              {auth?.user?.role === 1 ? <option value={'2'}>Client</option> : auth?.user?.role === 2 ? <>
                <option value={'2'}>Client</option>
                <option value={'3'}>active user </option>  </> : null}

            </select>
          </div>
          {auth?.user?.role === 2 && role === '3' ? <div className="mb-3 input_control">
            <input type="text" value={partNoRanges.join(', ')} onChange={(e) => setPartNoRanges(e.target.value.split(',').map(range => range.trim()))} className="form-control" placeholder='Part No Ranges (comma separated)' />
          </div> : null}
          <div className="text-center w-100" >
            <button type="submit" className="btn btn-primary w-50">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
