import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';

import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
// import Logo from '../../Assets/janLogo.png';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from "../../context/auth";
import {useNavigate} from 'react-router-dom'
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function AdminDashboard({handleDrawerOpen,open,userName,title}) {
  const theme = useTheme();
  const [auth] = useAuth();
  const navigate = useNavigate();
  // console.log(open);

  const handleLogout = () => {
    localStorage.removeItem("auth");
    navigate('/')
    window.location.reload();
  };
  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <div className='logoContainer' style={{ width: '40%',display:'flex',alignItems:'center' }}>
          <IconButton className='mobNon' edge="start" sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }} onClick={() => navigate('/')}>
            {/* <img src={Logo} alt="logo" width={'150px'} />  */}
           <h4 className='text-light'>Sambha Survey</h4> 
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          
          <Typography variant="h6" className='d-block mobNon' noWrap component="div">
            {title}
          </Typography>
          </div>
          <div className='userContainer' style={{ width: '60%' }}>
            <div className='userDetails_container'>
              <div className='Admin_Details'>
                <p>{auth?.user?.name}</p>
              </div>
              <div className='Admin_options'>
                {/* <IconButton><NotificationsIcon /></IconButton> */}
                <IconButton><PersonIcon /></IconButton>
                <IconButton onClick={handleLogout}><LogoutIcon /></IconButton>
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
}
