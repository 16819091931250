import React, { useState, useEffect } from "react";
import axios from "axios";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import { useNavigate } from "react-router-dom";
import Header from '../../components/layout/Header2';
import DrawerNav from "../../components/layout/Drawer";
import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import DescriptionIcon from '@mui/icons-material/Description';
import { IconButton } from "@mui/material";

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AddQuestions = () => {
  const [file, setFile] = useState(null);
  const [electionType, setElectionType] = useState('');
  const [state, setState] = useState('');
  const [district, setDistrict] = useState('');
  const [assemblyName, setAssemblyName] = useState('');
  const [constituency, setConstituency] = useState('');
  const [types, setTypes] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [assemblies, setAssemblies] = useState([]);
  const [constituencies, setConstituencies] = useState([]);
  const [questions, setQuestions] = useState([{ question: '', options: [''] }]);
  const navigate = useNavigate();
  const theme = useTheme();
  const notyf = new Notyf();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchTypes = async () => {
      try {
        const res = await axios.get('/api/v1/product/types', { headers: { 'Cache-Control': 'no-cache' } });
        setTypes(res.data.types || []);
      } catch (error) {
        console.error(error);
        notyf.error("Failed to fetch types");
      }
    };
    fetchTypes();
  }, []);

  useEffect(() => {
    if (electionType) {
      const fetchStates = async () => {
        try {
          const res = await axios.get(`/api/v1/product/states?type=${electionType}`, { headers: { 'Cache-Control': 'no-cache' } });
          setStates(res.data.states || []);
        } catch (error) {
          console.error(error);
          notyf.error("Failed to fetch states");
        }
      };
      fetchStates();
    }
  }, [electionType]);

  useEffect(() => {
    if (state && electionType) {
      const fetchConstituencies = async () => {
        try {
          const res = await axios.get(`/api/v1/product/constituencies?type=${electionType}&state=${state}`, { headers: { 'Cache-Control': 'no-cache' } });
          setConstituencies(res.data.constituencies || []);
        } catch (error) {
          console.error(error);
          notyf.error("Failed to fetch constituencies");
        }
      };
      fetchConstituencies();
    }
  }, [state, electionType]);

  useEffect(() => {
    if (electionType === "Vidhan Sabha" && state) {
      const fetchDistricts = async () => {
        try {
          const res = await axios.get(`/api/v1/product/districts?type=${electionType}&state=${state}`, { headers: { 'Cache-Control': 'no-cache' } });
          console.log(`Fetched districts: ${JSON.stringify(res.data.districts)}`); // Debugging line
          setDistricts(res.data.districts || []);
        } catch (error) {
          console.error(error);
          notyf.error("Failed to fetch districts");
        }
      };
      fetchDistricts();
    }
  }, [state, electionType]);

  useEffect(() => {
    if (electionType === 'Vidhan Sabha' && state && district) {
      const fetchAssemblies = async () => {
        try {
          const res = await axios.get(`/api/v1/product/assemblies?type=${electionType}&state=${state}&district=${district}`, { headers: { 'Cache-Control': 'no-cache' } });
          setAssemblies(res.data.assemblies || []);
        } catch (error) {
          console.error(error);
          notyf.error("Failed to fetch assemblies");
        }
      };
      fetchAssemblies();
    }
  }, [electionType, state, district]);

  useEffect(() => {
    if (electionType === 'Vidhan Sabha' && state && district && assemblyName) {
      const fetchConstituencies = async () => {
        try {
          const res = await axios.get(`/api/v1/product/constituencies?type=${electionType}&state=${state}&district=${district}&assembly=${assemblyName}`, { headers: { 'Cache-Control': 'no-cache' } });
          setConstituencies(res.data.constituencies || []);
        } catch (error) {
          console.error(error);
          notyf.error("Failed to fetch constituencies");
        }
      };
      fetchConstituencies();
    }
  }, [electionType, state, district, assemblyName]);


  const handleUpload = (e) => {
    e.preventDefault();
  
    // Assuming you have a way to select a single assembly from the user
    const selectedAssembly = assemblyName; // This should be the user-selected assembly
  
    const formData = new FormData();
    formData.append("electionType", electionType);
    formData.append("state", state);
    formData.append("district", district);
    formData.append("assemblyName", selectedAssembly); // Send only the selected assembly
    formData.append("constituency", constituency);
    formData.append("questions", JSON.stringify(questions));
  
    axios
      .post(`/api/v1/product/questions`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response);
        notyf.success("Data Uploaded Successfully");
      })
      .catch((error) => {
        console.error(error);
        notyf.error("Something went wrong");
      });
  };
  
  

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleAddQuestion = () => {
    setQuestions([...questions, { question: '', options: [''] }]);
  };

  const handleQuestionChange = (index, value) => {
    const newQuestions = [...questions];
    newQuestions[index].question = value;
    setQuestions(newQuestions);
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options[optionIndex] = value;
    setQuestions(newQuestions);
  };

  const handleAddOption = (questionIndex) => {
    const newQuestions = [...questions];
    newQuestions[questionIndex].options.push('');
    setQuestions(newQuestions);
  };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Header handleDrawerOpen={handleDrawerOpen} open={open} title={'Upload Data'} />
        <DrawerNav open={open} handleDrawerClose={handleDrawerClose} theme={theme} />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          <div className="">
            <div className="shadow uploadBox CustomUpload row">
              <div className="col-sm-6">
                <div className="mb-3">
                  <select value={electionType} onChange={(e) => setElectionType(e.target.value)} className="form-control" required>
                    <option value="" disabled>Select Election Type</option>
                    {types.length > 0 && types.map((type, idx) => <option key={idx} value={type}>{type}</option>)}
                  </select>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="mb-3">
                  <select value={state} onChange={(e) => setState(e.target.value)} className="form-control" required>
                    <option value="" disabled>Select State</option>
                    {states.length > 0 && states.map((state, idx) => <option key={idx} value={state}>{state}</option>)}
                  </select>
                </div>
              </div>

              {electionType === "Vidhan Sabha" ? (
                <>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <select value={district} onChange={(e) => setDistrict(e.target.value)} className="form-control" required>
                        <option value="" disabled>Select District</option>
                        {districts.length > 0 && districts.map((district, idx) => <option key={idx} value={district}>{district}</option>)}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <select value={assemblyName} onChange={(e) => setAssemblyName(e.target.value)} className="form-control" required>
                        <option value="" disabled>Select Assembly</option>
                        {assemblies.length > 0 && assemblies.map((assembly, idx) => <option key={idx} value={assembly}>{assembly}</option>)}
                      </select>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <select value={constituency} onChange={(e) => setConstituency(e.target.value)} className="form-control" required>
                        <option value="" disabled>Select Constituency</option>
                        {constituencies.length > 0 && constituencies.map((constituency, idx) => <option key={idx} value={constituency}>{constituency}</option>)}
                      </select>
                    </div>
                  </div>
                </>
              )}

            
            <div className="row">
    {questions.map((q, qi) => (
                <div className="col-sm-6">
                <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Question"
                      value={q.question}
                      onChange={(e) => handleQuestionChange(qi, e.target.value)}
                    />
                  </div>
                  {q.options.map((opt, oi) => (
                    <div key={oi} className="mb-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Option"
                        value={opt}
                        onChange={(e) => handleOptionChange(qi, oi, e.target.value)}
                      />
                    </div>
                  ))}
                  <button className="btn btn-secondary mb-3" onClick={() => handleAddOption(qi)}>
                    Add Option
                  </button>
                </div>
              ))}

<div className="col-sm-12 text-center">
                <button className="btn btn-primary mb-3"style={{ width: "40%", borderRadius: '30px' }}  onClick={handleAddQuestion}>
                  Add Question
                </button>
              </div>
            </div>

          
              <div className="col-sm-12 text-center">
                <button className="btn btn-danger uploadbtn" style={{ width: "40%", borderRadius: '30px' }} onClick={handleUpload}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default AddQuestions;
