import * as Constent from '../constent';
import axios from 'axios';

export const getAllVoters = () => {
    return async (dispatch) => {
        dispatch({ type: Constent.GET_ALL_VOTERS_REQUEST });

        try {
            const authData = localStorage.getItem("auth");
            const authObject = JSON.parse(authData);
            const user = authObject.user;
            const [start, end] = user.partNoRanges;
            
            const response = await axios.get('/api/v1/product/allvotes', {
                params: {
                    electionType: user.electionType,
                    state: user.state,
                    district: user.district,
                    assemblyName: user.assembly,
                    constituency: user.constituency,
                    start: start,
                    end: end
                }
            });
            
            dispatch({
                type: Constent.GET_ALL_VOTERS_SUCCESS,
                payload: {
                    data: response.data.data,
                    totalCount: response.data.data.length // get all voter length
                }
            });
            localStorage.setItem('allData', JSON.stringify(response.data.data));
            console.log('action....!!!');
        } catch (error) {
            console.error("Error fetching data:", error);
            dispatch({ type: Constent.GET_ALL_VOTERS_FAILURE, error });
        }
    };
};
