import React, { useEffect, useState, useRef } from "react";
import Layout from "../components/layout/Layout";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import HolidayVillageOutlined from "@mui/icons-material/HolidayVillageOutlined";

const VillageList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalVillages, setTotalVillages] = useState(0);
  // const pageSize = 100;
  const containerRef = useRef(null);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (
        containerRef.current &&
        window.innerHeight + window.scrollY >=
        containerRef.current.offsetTop + containerRef.current.offsetHeight
      ) {
        handleLoadMore();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [data]);

  const authData = localStorage.getItem("auth");
  const authObject = JSON.parse(authData);
  const user = authObject.user;
  const [start, end] = user.partNoRanges;
  
  const fetchData = async () => {
    try {
      if (navigator.onLine) {
        const response = await axios.get(
          `/api/v1/product/village`,{
            params : {
              electionType: user.electionType,
              state: user.state,
              district: user.district,
              assemblyName: user.assembly,
              constituency: user.constituency,
              start: start,
              end: end
             }
          }
        );
        console.log("API response:", response.data); // Log response for debugging
        setData((prevData) => [...prevData, ...response.data.data]);
        setTotalVillages(response.data.partnoCount);
        setLoading(false);
      } else {
        const offlineData = await fetchDataFromIndexedDB();
        setData((prevData) => [...prevData, ...offlineData]);
        setTotalVillages(offlineData.length);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("An error occurred while fetching data.");
      setLoading(false);
    }
  };
  
  const fetchDataFromIndexedDB = async () => {
    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open("voters-db", 1);

      request.onerror = (event) => {
        console.error("IndexedDB error:", event.target.error);
        reject("An error occurred while accessing IndexedDB.");
      };

      request.onsuccess = (event) => {
        const db = event.target.result;
        const transaction = db.transaction("votes", "readonly");
        const objectStore = transaction.objectStore("votes");

        const data = {};

        objectStore.openCursor().onsuccess = (event) => {
          const cursor = event.target.result;
          if (cursor) {
            const PART_NO = cursor.value.PART_NO;
            if (!data[PART_NO]) {
              data[PART_NO] = 1;
            } else {
              data[PART_NO]++;
            }
            cursor.continue();
          } else {
            resolve(
              Object.entries(data).map(([PART_NO, count]) => ({
                PART_NO,
                count,
              }))
            );
          }
        };

        objectStore.openCursor().onerror = (event) => {
          console.error("IndexedDB cursor error:", event.target.error);
          reject("An error occurred while fetching data from IndexedDB.");
        };
      };

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains("votes")) {
          const objectStore = db.createObjectStore("votes", {
            keyPath: "id",
            autoIncrement: true,
          }); // Ensure a unique ID for each entry
          objectStore.createIndex("PART_NO", "PART_NO", { unique: true }); // Index on PART_NO
          // Add other indexes or modify structure as needed
        }
      };
    });
  };

  const handleLoadMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return (
    <Layout title={"All Villages"}>

      <div 
        className="container mt-3"
        ref={containerRef}
      >
        <div className="row">
          <div className="col-md-12 familycard">
            {loading && <h2 className="text-center">Loading...</h2>}
            {error && <h2 className="text-center">{error}</h2>}
            </div>
            {data.length > 0 && (
              <>
                <h6 className="text-light mb-3">Total number of Parts: {totalVillages}</h6>
                {data.map((item, index) => {
                  return ( <div className="col-sm-4 mb-3" onClick={() => navigate(`/village/${encodeURIComponent(item.PART_NO)}`)}>
                    <div className="card" key={index}>
                      <div className="card-body text-center">
                        <IconButton>
                          <HolidayVillageOutlined style={{ fontSize: '40px' }} />
                        </IconButton>
                        <h4> Part: {item.PART_NO}
                        </h4>
                      </div>
                      <div className="card-footer text-center">
                        <h6>Total: {item.count}</h6>
                      </div>
                    </div>
                  </div>
                )})}

              </>
            )}
            {data.length === 0 && !loading && (
              <h2 className="text-center">No data found</h2>
            )}
          </div>
        </div>
      {fetching && <h2 className="text-center text-light">Loading more...</h2>}
    </Layout>
  );
};

export default VillageList;
