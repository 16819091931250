import React, { useState } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";
import SearchInput from "../Form/SearchInput";
import Avatar from "../../Assets/avatar.png";
// import LogoJan from "../../Assets/janLogo.png"

export default function Header() {
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();
  const [active, setActive] = useState(false);

  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
  };

  const handleClick = () => {
    setActive(!active);
  };
  const handleLogoClick = () => {
    navigate("/");
    // window.location.reload();
  };
  return (
    <nav className="navbar navbar-expand-lg navbar_bg sticky-top">
      <div className="container">
        <Link to="/" className="navbar-brand">
          {/* <h4 onClick={handleLogoClick}>Goa Congress</h4> */}
          <h4 className="text-light" onClick={handleLogoClick}> Samba Survey</h4>
        </Link>
        <div className="nav-avatar mob_576" type="button" onClick={handleClick}>
          <img src={Avatar} alt="user" />
        </div>

        <div className={`collapse navbar-collapse ${active ? "show" : ""}`}>
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink to="/voterList" className="nav-link">
                Search
              </NavLink>
            </li>

            {!auth?.user ? (
              <>
                <li className="nav-item">
                  <NavLink to="/register" className="nav-link">
                    Register
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/login" className="nav-link">
                    Login
                  </NavLink>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item dropdown">
                  <NavLink
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {auth?.user?.name}
                  </NavLink>
                  <ul className="dropdown-menu">
                    {auth?.user?.role === 0 || auth?.user?.role === 3 ? null : <li>
                      <NavLink
                        className="dropdown-item"
                        to={`/dashboard/${auth?.user?.role === 1 ? "admin" : "user"
                          }`}
                      >
                        Dashboard
                      </NavLink>
                    </li>}
                    <li>
                      <NavLink
                        className="dropdown-item"
                        to="/"
                        onClick={handleLogout}
                      >
                        Logout
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </>
            )}
            <li className="nav-item">
              <NavLink to="/contact" className="nav-link non-active">
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
