import * as Constent from '../constent';

const initialState = {
    AllVoterData:null,
    totalCount:0,
    loading:false,
    fetching:false,
    error:''
}

export const Get_All_VotersReducer = (state = initialState, action) => {
  switch (action.type) {
      case Constent.GET_ALL_VOTERS_REQUEST:
          return { ...state, loading: true };
      case Constent.GET_ALL_VOTERS_SUCCESS:
          return { ...state, AllVoterData: action.payload.data, totalCount: action.payload.totalCount, loading: false };
      case Constent.GET_ALL_VOTERS_FAILURE:
          return { ...state, error: action.error, loading: false };
      default:
          return state;
  }
};